import { Button, Checkbox, Col, Form, Image, Input, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import accountIcon from 'assets/image/account.svg';
import langIcon from 'assets/image/language.svg';
import passwordIcon from 'assets/image/password.svg';
import { ModalLayout } from "components/layout.component";
import { COOKIE } from "constants/cookie";
import { LANG } from "constants/socket";
import useAccount from "hooks/account.hook";
import useSite from "hooks/site.hook";
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { $get, $post } from "services";
import { langChange } from "utils/common";

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAccount();

  // 聖經once
  const { errorCode } = useParams();
  const initialized = useRef(false)
  useEffect(() => {
    if (errorCode && !initialized.current) {
      initialized.current = true;
      // message.error(RESPONSE_CODE[errorCode as keyof typeof RESPONSE_CODE]);
    }
  }, []);

  const [form] = useForm();
  const [rememberMe, setRememberMe] = useState(Cookies.get(COOKIE.REMEBER_ME) === 'true');

  useEffect(() => {
    // NOTE: 應該要能用setFieldsValue就改變Checkbox
    const rememberMe = Cookies.get(COOKIE.REMEBER_ME) === 'true'
    if (rememberMe) {
      form.setFieldsValue({
        account: Cookies.get('account'),
      })
      setRememberMe(rememberMe)
    }
  }, [])

  const { data: $s } = useSite();
  useEffect(() => {
    if ($s?.Language) {
      const lang = Cookies.get('lang')
      if (!lang) {
        langChange($s.Language, true)
      } else {
        form.setFieldsValue({
          Language: lang
        })
      }
    }
  }, [$s])

  const { t, i18n } = useTranslation();
  const { data: languageList, isValidating } = $get({
    url: 'common/language/list',
    auth: false
  });

  const onFinish = (formData: any) => {
    $post({
      url: 'common/auth/login',
      send: {
        Account: formData.account,
        Password: formData.password
      },
      success: (response: any) => {
        login(response.Data.Token);
        Cookies.set('account', formData.account);
        if (rememberMe) {
          Cookies.set('account', formData.account);
        }
        Cookies.set(COOKIE.REMEBER_ME, rememberMe ? 'true' : 'false');

        message.success(i18n.t('loginSuccess'));
        form.resetFields();
        navigate('/');
      },
    })
  };

  return (
    <ModalLayout>
      <Form size="middle" onFinish={onFinish} form={form}>
        <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
          <div>{i18n.t('agentBackend')}</div>
        </Form.Item>
        <Form.Item name="Language">
          <Select
            loading={isValidating}
            onChange={val => langChange(val, true)}
            options={
              languageList?.Data?.map((item: any) => ({
                value: item,
                label: (
                  <Row align="middle" gutter={[5, 5]}>
                    <Col><Image src={langIcon} width={20} height={20} preview={false} /></Col>
                    <Col>{LANG[item as keyof typeof LANG]}</Col>
                  </Row>
                )
              })) || []
            }
          />
        </Form.Item>
        <Form.Item
          className="mt-1"
          name="account"
          rules={[{ required: true, message: `${i18n.t("enterAccount")}` }]}
        >
          <Input
            prefix={
              <Image rootClassName="center" src={accountIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t("inputData")}${i18n.t("account")}`}
          />
        </Form.Item>
        <Form.Item
          className="mt-1"
          name="password"
          rules={[{ required: true, message: `${i18n.t("enterPassword")}` }]}
        >
          <Input.Password
            prefix={
              <Image rootClassName="center" src={passwordIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t("inputData")}${i18n.t("password")}`}
          />
        </Form.Item>
        <Form.Item className="center mt-1" name="remember">
          <Checkbox checked={rememberMe} onChange={(e: any) => setRememberMe(e.target.checked)}>
            {i18n.t("rememberAccount")}
          </Checkbox>
        </Form.Item>
        <Form.Item className="center mt-1" name="login">
          <Button
            htmlType="submit"
            style={{ background: '#DABF8D' }}
            className="w-10 login-btn"
            size="large"
          >
            {i18n.t("login")}
          </Button>
        </Form.Item>
      </Form>
    </ModalLayout>
  );
};

export default PageMain;
