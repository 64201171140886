import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Decimal from 'decimal.js';
import { MEMBER_BANK_STATE, STATE, WITHDRAW_STATE } from 'enum/state';
import i18n from 'i18n';
import Cookies from 'js-cookie';

export enum IP_STATE {
  safe = 1,
  warn = 7,
  danger = 8,
}

// 銀行末四碼
export function displayLastFourCharacters(input: string) {
  if (input.length > 0) {
    var lastFourCharacters = input.substring(input.length - 4);
    return lastFourCharacters;
  } else {
    return ''
  }
}

// 數字格式
// 預設四捨五入; 無條件捨去roundType帶入'floor'; 無條件進位roundType帶入'ceil'
export function toFormatNumber(num: any, fraction: number = 4, roundType?: 'floor' | 'ceil'): string {
  const userLocale = navigator.language;
  const toNum = isNaN(num) ? 0 : Number(num);
  const factor = Math.pow(10, fraction);
  let roundedNum: number;
  switch (roundType) {
    case 'ceil':
      roundedNum = Math.ceil(toNum * factor) / factor;
      break;
    case 'floor':
      roundedNum = Math.floor(toNum * factor) / factor;
      break;
    default:
      roundedNum = Math.round(toNum * factor) / factor;
      break;
  }
  const formattedNumber = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(roundedNum);
  return formattedNumber;
}

export function decimalNumber(input: string): boolean {
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(input)
}

export function leftPad(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

// 正的是黑色
export function numColor01(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) > 0)
    return 'color-up'
  else if (Number(num) < 0)
    return 'color-down'
  else
    return 'unchange'
}

export function numColor02(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) <= IP_STATE.safe)
    return 'color-safe'
  else if (Number(num) <= IP_STATE.warn)
    return 'color-warn'
  else if (Number(num) >= IP_STATE.danger)
    return 'color-danger'
}

// 正的是綠色
export function numColor03(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-0'
  else if (Number(num) > 0)
    return 'color-up-0'
  else
    return 'color-04'
}

export function numColor04(type: number) {
  switch (type) {
    case WITHDRAW_STATE.approvaled:
      return 'color-pass'
    case WITHDRAW_STATE.reject:
    case WITHDRAW_STATE.fail:
      return 'color-reject'
    case WITHDRAW_STATE.pendingSupervisorReview:
    case WITHDRAW_STATE.pendingServiceReview:
    case WITHDRAW_STATE.pendingFinancialReview:
    case WITHDRAW_STATE.financialReview:
      return 'color-reviewing'
    case WITHDRAW_STATE.processing:
    case WITHDRAW_STATE.paymentTerminalWaiting:
      return 'color-padding'
  }
}

// 標籤式
export function numColor05(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-tag'
  else if (Number(num) > 0)
    return 'color-up-tag'
  else
    return 'color-tag'
}

// FIXME: 輸入狀態都不同 是否能整合
// 狀態顏色
export function stateColor(state: number) {
  switch (state) {
    case STATE.pass:
      return 'color-pass'
    case STATE.reject:
      return 'color-reject'
    case STATE.padding:
      return 'color-padding'
    default: return ''
  }
}

export function stateColorBank(state: number) {
  switch (state) {
    case MEMBER_BANK_STATE.waitVerify:
      return 'color-padding';
    case MEMBER_BANK_STATE.verificationSuccessfulOpen:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationSuccessfulClose:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationFailed:
      return 'color-reject';
    case MEMBER_BANK_STATE.delete:
      return 'color-down-0';
  }
}

// 轉換成百分比 1 => 100
export function convertedToPercentage01(num: number | string): string {
  let decimalValue;

  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    decimalValue = new Decimal(parsedValue);
  }

  decimalValue = new Decimal(num);

  return decimalValue.mul(100).toFixed(2).toString();
}

// 轉換成百分比 100 => 1
export function convertedToPercentage02(num: number | string): string {
  let decimalValue;
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    if (!isNaN(parsedValue)) {
      decimalValue = new Decimal(parsedValue).toDP(2);
    } else {
      throw new Error('Invalid input: Not a valid number or string that can be parsed into a number.');
    }
  } else if (typeof num === 'number') {
    decimalValue = new Decimal(num).toDP(2);
  } else {
    throw new Error('Invalid input: Input must be a number or a string that can be parsed into a number.');
  }

  return decimalValue.div(100).toString();
}

// 欄位驗證
export function verify({
  required = true,
  point = 2,
  max = null,
  min = null,
  negative = false,
  zero = true
}: any) {
  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = new RegExp('^-?\\d{1,100}(\\.\\d{0,0})?$');
        let msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('onlyIntegersCanBeEntered')}`;

        if (point === 2) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,2})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToTwoDecimalPlaces')}`;
        } else if (point === 4) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,4})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToFourDecimalPlaces')}`;
        } else if (point === 5) {
          regex = new RegExp('[a-zA-Z]{1}[a-zA-Z0-9]{3,11}$');
          msg = i18n.t('englishAndNumbersWithin4To12Characters');
        } else if (point === 6) {
          regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
          msg = i18n.t('supportSpecialCharacters');
        } else if (point === 7) {
          regex = new RegExp('^[0-9]+$');
          msg = i18n.t('invalidFormat');
        }

        // 只能是數字
        if (isNaN(value) && value && point !== 5 && point !== 6 && point !== 7) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 只能是正整數
        if ((!negative && Number(value) < 0) || (point === 0 && !regex.test(value))) {
          return Promise.reject(i18n.t('onlyCanBePositiveIntegers'));
        }

        // 不能為0 但選填
        if (!zero && (typeof value !== 'undefined' && value !== null) && Number(value) === 0) {
          return Promise.reject(`${i18n.t('mustBeGreaterThan')}0`);
        }

        // 正數
        if (!negative && Number(value) < 0) {
          return Promise.reject(i18n.t('contentOnlyNumber'));
        }

        // 小數點
        if (value && !regex.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 超過最大
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${ max }`);
        }
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(i18n.t('rateMax'));
        }
        // 限制最小
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${ min }`);
        }
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(i18n.t('rateMin'));
        }

        return Promise.resolve();
      }
    }
  ];
}

// enum 轉成下拉選單
export function enumToOptions(e: any) {
  return Object.keys(e).filter(v => isNaN(Number(v))).map((k: any) => ({ value: e[k], label: i18n.t(k) }))
}

// 顯示幾代與代理名稱
export function agentLevelWord(level: any) {
  return `${level === 0 ? i18n.t('totalAg') : level}${i18n.t('agents')}`;
}

export function transferTimeFormat(time: number) {
  return dayjs(time * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export const $mobile = window.innerWidth < 1300
// 螢幕: 1920
// 筆電: 1440
// pad: 1024
// phone: 430

// 多語系切換 i: jsonString o:name string
export function gameNameTransfer(json: string, defaultName: string) {
  const userLang = Cookies.get('lang');
  const data = JSON.parse(json || '[]');
  const transfer = data.find((item: { lang: string }) => item.lang === userLang);
  return transfer ? transfer.name : defaultName;
}

// 切換語系
export async function langChange(value: string, reload: boolean) {
  Cookies.set('lang', value);
  Cookies.set('lang-online-service', value);
  if (reload) {
    window.location.reload();
  } else {
    i18n.changeLanguage(value);
  }
}

dayjs.extend(utc);
dayjs.extend(timezone);

export function timeL2S(timeString: string) {
  // 送出資料時 要告知當地時區
  if (timeString) return dayjs(timeString).format('YYYY-MM-DDTHH:mm:ssZ');
  else return timeString;
}

// 出字串沒錯
export function timeL2SDay(day: any, format: string = 'YYYY-MM-DDTHH:mm:ssZ') {
  if (day) return day.format(format);
  else return day;
}

export function timeS2L(timeString: string, format: string = 'YYYY-MM-DD HH:mm:ss') {
  // 前端收到後 要加上本地時區 GMT+08:00:00
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (timeString === '0000-00-00 00:00:00') return '-';
  else if (timeString) return dayjs.tz(timeString, serverZone).tz().format(format);
  else return timeString;
}

export function timeS2LUnix(unix: number, format?: string) {
  const timeString = dayjs.unix(unix).format('YYYY-MM-DDTHH:mm:ss');
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (unix) {
    if (format) return dayjs.tz(timeString, serverZone).tz().format(format);
    else return dayjs.tz(timeString, serverZone).tz().unix();
  }
  else return unix;
}

// 字串出days
export function timeS2LDay(timeString: any) {
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (timeString) return dayjs.tz(timeString, serverZone).tz()
  else return timeString;
}

// 下拉搜尋條件
export function filterOption(input: string, option?: { label: string; value: string }) {
  const label = (option?.label ?? '').toLowerCase();
  const text = input.toLowerCase();
  return label.includes(text);
}

// 操作記錄-操作選單
export function filterOperation(ary: any, find: any) {
  return ary.filter((item: any) => item.ManagementId === find)
}

// BE GM 特規
export function specialProviderName(input: string | any, change: string, changeOther?: string) {
  return input === 'SELF' ? `${change}(${changeOther})` : input
}

// 比較兩個物件內容是否相同
export function objectsEqual(a: any, b: any) {
  let result = true; 
  const av = Object.values(a);
  const bv = Object.values(b);
  av.forEach((v, i) => {
    if (v !== bv[i]) result = false;
  })
  return result;
}

// 整理逗號字串雜訊
export function cleanCommaString(str: string) {
  const dirty = str.split(',');
  const clean = dirty.map((item: any) => item.trim()).filter((item: any) => item !== '');
  return clean.join(',');
}

// 移除陣列重複項目
export function removeDuplicates(arr: string[]) {
  return arr.filter((item: any, index: number) => arr.indexOf(item) === index);
}
