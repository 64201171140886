import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Table, Tooltip, message } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import type { RangePickerProps } from 'antd/es/date-picker';
import { Content } from 'antd/es/layout/layout';
import { Image } from 'antd/lib';
import remarkIcon from 'assets/image/icon-11.svg';
import { DatePickerCol, ExportExcel, LayoutNav } from 'components/layout.component';
import { PopupAddAgentModal, PopupAgentMemoModal } from 'components/team.component';
import { COOKIE } from 'constants/cookie';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { $get } from 'services';
import { setSearchHistory } from 'slice/searchSlice';
import { RootType } from 'store/configureStore';
import { toFormatNumber as $f, $mobile, timeL2S, timeS2L } from "utils/common";

// 代理列表

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const icon = useIcon();
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [download, setDownload] = useState(false);
  const [downloadData, setDownloadData] = useState(null);
  const [tempPagesize, setTempPagesize] = useState(10);

  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);

  // 搜尋儲存
  const { agent: searchHistory } = useSelector((state: RootType) => state.Search);

  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    PageIndex: 1,
    PageSize: 10,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
  });
  const search = (formData: any) => {
    const obj = {
      Account: formData.Account,
      PageIndex: 1,
      PageSize: searchParams.PageSize,
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1]),
    }
    setSearchParams(obj);
    dispatch(setSearchHistory({ agent: obj }));
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  }

  const [agentList, setAgentList] = useState<TableAttributes>({});
  const { data: _agentList, mutate, isValidating } = $get({
    url: 'api/report/agent/list',
    params: searchParams
  })

  useEffect(() => {
    if (_agentList && _agentList.Data) {
      const data = _agentList.Data.map((item: any) => ({ key: item.Id, ...item }))
      if (download) {
        setDownloadData(data);
        setDownload(false);
        setSearchParams({ ...searchParams, PageSize: tempPagesize });
        setTimeout(() => {
          setDownloadData(null);
        }, 200);
      } else {
        setAgentList({
          dataSource: data,
          total: _agentList.TotalRecord
        })
      }
    } else {
      setAgentList({
        dataSource: [],
        total: 0
      })
    }
  }, [_agentList]);

  const handleTableChange = (pagination: any) => {
    const param = searchHistory || searchParams
    setSearchParams({ ...param, PageIndex: pagination.current, PageSize: pagination.pageSize })
    dispatch(setSearchHistory({ agent: { ...param, PageIndex: pagination.current, PageSize: pagination.pageSize } }));
  }

  const exportExcel = async () => {
    const query = QueryString.stringify({
      ...searchParams,
      PageIndex: 1,
      PageSize: 9999,
    }, { addQueryPrefix: true, });
    try {
      await fetch(`/api/report/agent/list${query}`, {
        headers: {
          "Authorization": Cookies.get(COOKIE.TOKEN) as any
        },
        method: 'GET',
      }).then(res => res.json())
        .then(resData => {
          const data = resData?.Data;
          if (data.length !== 0) {
            const excel = new Excel();
            excel
              .addSheet(i18n.t('generalAgentList'))
              .addColumns(ExcelColumns.AgentList)
              .addDataSource(data, {
                str2Percent: true
              })
              .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob")
            message.success(i18n.t('exportSuccessfully'));
          }
        })
    } catch (error) {
      message.success(i18n.t('exportFailed'));
      return;
    }
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const { isCashVersion: $sc } = useSite();

  return (
    <>
      <LayoutNav />
      <Content>
        <Form className='form-search' form={form} onFinish={search}>
          <Row gutter={[12, 12]} style={{ maxWidth: '1300px' }}>
            <Col span={$mobile ? 24 : 4}>
              <Form.Item name="Account">
                <Input placeholder={`${i18n.t('account')}`} />
              </Form.Item>
            </Col>
            <DatePickerCol span={$mobile ? 24 : 4} initDate={initDate} date={date} setDate={setDate} textAlign='right'
              rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col span={24}>
              <Row justify={$mobile ? 'end' : 'start'} gutter={[12, 12]}>
                <Col>
                  <Tooltip placement="top" className="size-12" title={i18n.t('dataIsNotAffectedByTimeQuery')}>
                    <InfoCircleFilled style={{ paddingTop: '10px', paddingLeft: '7px' }} />
                  </Tooltip>
                </Col>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify={'start'} gutter={[12, 12]}>
                {$p('0202') &&
                  <Col>
                    <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
                  </Col>}
                {$p('0203') &&
                  <Col>
                    <ExportExcel exportExcel={exportExcel} />
                  </Col>}
              </Row>
            </Col>
          </Row>
        </Form>
        <Table
          className='mt-1'
          loading={isValidating}
          size="small"
          scroll={{ x: 400 }}
          dataSource={agentList.dataSource}
          columns={[
            {
              dataIndex: 'Account',
              title: i18n.t('account'),
              width: 120,
              fixed: 'left',
              render: (val: any, record: any, index: any) => (
                <>
                  {/* <span className="mr-1">{index + 1}</span> */}
                  <span>{val}</span>
                </>
              )
            },
            {
              dataIndex: 'AgentCount',
              title: i18n.t('agentCount'),
              align: 'right',
              width: 120,
              // sorter: (a, b) => a.agentCount - b.agentCount,
              render: (val: any) => $f(val, 0)
            },
            {
              dataIndex: 'MemberCount',
              title: i18n.t('memberCount'),
              align: 'right',
              width: 120,
              // sorter: (a, b) => a.memberCount - b.memberCount,
              render: (val: any) => $f(val, 0)
            },
            {
              dataIndex: 'DepositAmount',
              title: i18n.t('deposit'),
              align: 'right',
              width: 180,
              // sorter: (a, b) => a.recharge - b.recharge,
              render: (val: any) => $sc ? $f(val) : '-'
            },
            {
              dataIndex: 'WithdrawAmount',
              title: i18n.t('withdraw'),
              align: 'right',
              width: 180,
              // sorter: (a, b) => a.withdraw - b.withdraw,
              render: (val: any) => $sc ? $f(val) : '-'
            },
            {
              dataIndex: 'ValidBetAmount',
              title: i18n.t('betAmountValid'),
              align: 'right',
              width: 180,
              // sorter: (a, b) => a.validBet - b.validBet,
              render: (val: any) => $f(val)
            },
            {
              dataIndex: 'WinLossAmount',
              title: i18n.t('winLose'),
              align: 'right',
              width: 180,
              // sorter: (a, b) => a.winLoss - b.winLoss,
              render: (val: any) => $f(val)
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              align: 'center',
              width: 150,
              render: (val: any) => (
                <Row align="middle" justify="center"><Col>{icon.MemberStateTag(val)}</Col></Row>
              )
            },
            {
              dataIndex: 'CreateDate',
              title: i18n.t('createTime'),
              width: 170,
              render: (val: any) => timeS2L(val)
            },
            {
              dataIndex: 'LastLoginTime',
              title: i18n.t('lastLoginTime'),
              width: 170,
              render: (val: any) => timeS2L(val) || '-'
            },
            {
              dataIndex: 'Remark',
              title: i18n.t('comment'),
              width: 200,
              // NOTE: memo抽共用
              render: (val: any, record: any) => (
                <Space>
                  <Space direction="vertical">
                    {
                      val?.length > 20 ?
                        <Tooltip placement="top" title={val}>
                          <span>
                            {
                              val.split('').map((text: string, i: number) => {
                                let outOf20 = '';
                                if (i <= 20) outOf20 += text
                                return outOf20;
                              })
                            }
                            ...
                          </span>
                        </Tooltip> :
                        <span>{val || '-'}</span>
                    }
                    {$p('0204') && <Button
                      className="center"
                      size="small"
                      type="link"
                      onClick={() => {
                        setIsEditCommentModal(true);
                        setCurrentAgent(record);
                      }}
                    >
                      <Image className="center" src={remarkIcon} preview={false} />
                    </Button>}
                  </Space>
                </Space>
              )
            },
            {
              dataIndex: 'operation',
              title: i18n.t('operation'),
              width: 80,
              fixed: 'right',
              render: (_: any, record: any) => (
                <Button style={{ padding: 0 }} className="size-12" type="link"
                  onClick={() => navigate({ pathname: generatePath('/team/agent-edit/:id', { id: record.Id }) })}
                >
                  {i18n.t('edit')}
                </Button>
              )
            },
          ].filter((item: any) => !(item.dataIndex === 'operation' && !$p('0202'))) as any}
          pagination={{
            showSizeChanger: true,
            showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
            total: agentList.total,
            current: (searchHistory || searchParams).PageIndex,
            pageSize: searchParams.PageSize
          }}
          onChange={handleTableChange}
        />

        <PopupAgentMemoModal currentAgent={currentAgent} isOpen={isEditCommentModal} close={() => setIsEditCommentModal(false)} mutate={mutate} />
        <PopupAddAgentModal isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} reload={mutate} />
      </Content>
    </>
  );
};

export default PageMain;
