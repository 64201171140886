import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { DailyList, SelectCycleDate } from 'components/team.component';
import dayjs from 'dayjs';
import { SETTLEMENT } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 返水占成報表

enum SETTLE_CYCLE_DAYJS {
  day = 0,
  week = 1,
  month = 2,
}

const PageMain: React.FC = () => {
  const { info, permissions: $p } = useAccount();

  let commissionUnits: any = SETTLE_CYCLE_DAYJS[info.CommissionSettleCycle as any]
  let revenueUnits: any = SETTLE_CYCLE_DAYJS[info.RevenueShareSettleCycle as any]

  const [periodRevenue, setPeriodRevenue] = useState<string>("");
  const { data: _reportRevenueList, isValidating: revenueValidating } = $get({
    url: 'api/report/revenue/list',
    params: {
      startDate: periodRevenue,
      endDate: dayjs(periodRevenue).endOf(commissionUnits).format('YYYY-MM-DD')
    },
    allow: periodRevenue && commissionUnits
  })
  const [periodRevenueshare, setPeriodRevenueshare] = useState<string>("");
  const { data: _reportRevenueshareList, isValidating: revenueshareValidating } = $get({
    url: 'api/report/revenueshare/list',
    params: {
      startDate: periodRevenueshare,
      endDate: dayjs(periodRevenueshare).endOf(revenueUnits).format('YYYY-MM-DD')
    },
    allow: periodRevenueshare && revenueUnits
  })

  return (
    <>
      <LayoutNav />
      <Content>

        <Row align="middle" className="mt-2" gutter={[5, 5]}>

          <Col span={24} className="size-14 font-w-md">{i18n.t('rebateReport')}</Col>
          <Col span={common.$mobile ? 12 : 5}>
            <SelectCycleDate setPeriod={setPeriodRevenue} mode={SETTLEMENT.revenue} />
          </Col>
          {/* 返水 */}
          <Col span={24}>
            <DailyList loading={revenueValidating} scroll={{ x: 400 }} reset={periodRevenue}
              data={_reportRevenueList?.Data?.map((item: any) => ({ Account: item.AgentAccount, ...item }))}
              columns={[
                {
                  key: 1,
                  dataIndex: 'Account',
                  title: i18n.t('account'),
                  className: 'size-12',
                  fixed: 'left',
                  width: 150,
                  render: (val: any, { AgentLevel }: any) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return val ? `${word}／${val}` : ''
                  }
                },
                {
                  key: 2,
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  className: 'size-12',
                  width: 150,
                  render: (val: any) => val === 'ALL' ? i18n.t('all') : i18n.t(val)
                },
                {
                  key: 3,
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('accumulatedValidBets'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => $f(val)
                },
                {
                  key: 4,
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('totalMemberWinLoss'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  key: 5,
                  dataIndex: 'DownlineCommission',
                  title: i18n.t('subordinateCashback'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => (
                    $f(val)
                    // <>
                    //   <span style={{ marginRight: 5 }}>{$f(agentPayouts.amount)}</span>
                    //   {agentPayouts.percentage && <Tag style={{ background: '#757575', color: '#fff' }}>{agentPayouts.percentage}%</Tag>}
                    // </>
                  )
                },
                {
                  key: 6,
                  dataIndex: 'Commission',
                  title: i18n.t('myRebate'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => (
                    <div className={common.numColor05(val)}>{common.toFormatNumber(val)}</div>
                    // <>
                    //   <Tag style={{ background: '#A7372D', color: '#fff' }}>{$f(payouts.amount)}</Tag>
                    //   {payouts.percentage && <Tag style={{ background: '#757575', color: '#fff' }}>{payouts.percentage}%</Tag>}
                    // </>
                  )
                },
              ]}
            />
          </Col>

          <Col span={24} className="size-14 font-w-md mt-1">{i18n.t('revenueReport')}</Col>
          <Col span={common.$mobile ? 12 : 5}>
            <SelectCycleDate setPeriod={setPeriodRevenueshare} mode={SETTLEMENT.revenueshare} />
          </Col>
          {/* 占成 */}
          <Col span={24}>
            <DailyList loading={revenueshareValidating} scroll={{ x: 400 }} reset={periodRevenueshare}
              data={_reportRevenueshareList?.Data?.map((item: any) => ({ Account: item.AgentAccount, ...item }))}
              columns={[
                {
                  key: 1,
                  dataIndex: 'Account',
                  title: i18n.t('account'),
                  className: 'size-12',
                  fixed: 'left',
                  width: 150,
                  render: (val: any, { AgentLevel }: any) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return val ? `${word}／${val}` : ''
                  }
                },
                {
                  key: 2,
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  className: 'size-12',
                  width: 150,
                  render: (val: any) => val === 'ALL' ? i18n.t('all') : i18n.t(val)
                },
                {
                  key: 3,
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('accumulatedValidBets'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => $f(val)
                },
                {
                  key: 4,
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('totalMemberWinLoss'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  key: 5,
                  // 總成本
                  dataIndex: 'TotalCost',
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  title: i18n.t('totalCost'),
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  key: 6,
                  dataIndex: 'DownlineRevenueShare',
                  title: i18n.t('subordinateCommissionRate'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => $f(val)
                },
                {
                  key: 7,
                  dataIndex: 'DownlineAgentCost',
                  title: i18n.t('subordinateCost'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  key: 8,
                  dataIndex: 'DownlineAgentProfit',
                  title: i18n.t('subordinateProfit'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  key: 9,
                  dataIndex: 'RevenueShare',
                  title: i18n.t('myCommissionRate'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any) => $f(val)
                },
                {
                  key: 10,
                  dataIndex: 'AgentCost',
                  title: i18n.t('myCost'),
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  key: 11,
                  dataIndex: 'AgentProfit',
                  className: 'size-12',
                  align: 'right',
                  width: 200,
                  title:
                    <Row align="middle" justify="end" gutter={[5, 5]}>
                      <Col>
                        <Tooltip placement="top" title={
                          i18n.t('myProfitMemberWinLossPromotionCashbackCommissionRate1PositiveValueAgentProfitNegativeValueAgentLoss')
                        }>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                      <Col className="size-12">{i18n.t('myProfit')}</Col>
                    </Row>,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ?
                    <div className={common.numColor05(val)}>{$f(val)}</div> : '-'
                },
              ]}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageMain;