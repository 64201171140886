import { Button, Col, Form, Image, Input, Modal, Popover, Row, Select, Space, Spin, Table, Tooltip, message, Checkbox } from "antd";
import { DatabaseFilled } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import remarkIcon from 'assets/image/icon-11.svg';
import hintIcon from 'assets/image/icon-12.png';
import { REGEX_ACCOUNT } from "constants/regex";
import { MEMBER_LOG } from "enum/cashlog";
import { MEMBER_FREEZE, REBATE_SETTINGS, SETTLEMENT, transfer } from "enum/state";
import useIcon from "hooks/icon.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { $get, $post } from "services";
import * as common from 'utils/common';
import {timeS2L} from "utils/common";
import { toFormatNumber as $f, convertedToPercentage01 as $g } from 'utils/common';
import { handleTableChange } from "utils/table";
import { CopyButton } from "./layout.component";
import { RESPONSE_CODE } from "../constants/response";
import useAccount from "hooks/account.hook";

// 會員列表
export const ViewMemberList = ({ searchParams, memberTableChange, refs }: any) => {
  useImperativeHandle(refs, () => ({

    mutate: mutate,

  }));
  const icon = useIcon();
  const { init, permissions: $p } = useAccount();
  const { data: $s, isCashVersion: $sc } = useSite();
  const childRef = useRef<any>();
  const topRef = useRef<any>();
  const navigate = useNavigate();
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);
  const [isOpenEditMemberTopUp, setIsOpenEditMemberTopUp] = useState(false);
  const [isOpenEditPayRecord, setIsOpenEditPayRecord] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState('0');
  const [transferMode, setTransferMode] = useState(transfer.add);

  const { data: _memberList, mutate, isValidating } = $get({
    url: 'api/member/list',
    params: searchParams,
  })
  // const { data: _memberList, mutate, isValidating } = GetMemberList(searchParams);

  const [columns, setColumns] = useState<any>([]);
  // 聖經欄位顯示設定
  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked

        return true
      }
    })
    setColumns([...columns]);
  };

  const waterHover = (record: any, word: any) => <>
    <Tooltip placement="bottom" title={
      <Spin spinning={false}>
        <div>{`${i18n.t('EGAMES')}: ${$g(record.EGamesCommissionRate)}%`}</div>
        <div>{`${i18n.t('LIVE')}: ${$g(record.LiveCommissionRate)}%`}</div>
        <div>{`${i18n.t('SPORTS')}: ${$g(record.SportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('ESPORTS')}: ${$g(record.ESportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('POKER')}: ${$g(record.PokerCommissionRate)}%`}</div>
        <div>{`${i18n.t('FISHING')}: ${$g(record.FishingCommissionRate)}%`}</div>
        <div>{`${i18n.t('COCKFIGHT')}: ${$g(record.CockfightCommissionRate)}%`}</div>
        <div>{`${i18n.t('LOTTERY')}: ${$g(record.LotteryCommissionRate)}%`}</div>
        <div>{`${i18n.t('SLOT')}: ${$g(record.SlotCommissionRate)}%`}</div>
      </Spin>
    }>
      {word}
    </Tooltip>
  </>

  useEffect(() => {
    if(init){
      setColumns([
        {
          dataIndex: 'Account',
          title: i18n.t('memberAccount'),
          width: 150,
          fixed: 'left',
          show:true,
          render: (val: any, record: any, i: any) => (
            <Row justify={'start'} align={'middle'}>
              {/* <div className="mr-1">{i + 1}</div> */}
              <Popover
                title={`${i18n.t('memberInformation')} (${val})`}
                placement="bottomLeft"
                content={() => (
                  <Space style={{ minWidth: 200 }} direction="vertical">
                    <div className='text-start size-12'>
                      <div>{i18n.t('realName')}：{record.Name || '-'}</div>
                      <div>{i18n.t('nickname')}：{record.Nickname || '-'}</div>
                      <div>{i18n.t('id')}：{record.Id || '-'}</div>
                      <div>{i18n.t('birthday')}：{record.Birth !== '0000-00-00' ? record.Birth : '-'}</div>
                    </div>
                  </Space>
                )}
              >
                <Button className="size-12" style={{ height: '29px' }} type="link">{val}</Button>
              </Popover>
              <CopyButton text={val} />
            </Row>
          )
        },
        {
          dataIndex: 'AGAccount',
          title: i18n.t('agent'),
          width: 150,
          show:true
        },
        {
          dataIndex: 'IsFreeze',
          title: `${i18n.t('online')}／${i18n.t('status')}`,
          width: 150,
          show:true,
          render: (val: any, { IsOnline }: any) => (
            <Row align="middle">
              <Col>{icon.StatusTag(IsOnline)}</Col>
              <Col>{icon.MemberStateTag(val ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal)}</Col>
            </Row>
          )
        },
        {
          dataIndex: 'Status',
          title: i18n.t('audit'),
          width: 120,
          show:true,
          render: (val: any) => (
            <Row><Col>{icon.MemberStateTag(val)}</Col></Row>
          )
        },
        {
          dataIndex: 'MemberLevelName',
          title: i18n.t('memberLevel'),
          width: 100,
          show:true,
          render: (val: any, record: any) => $s.CommissionModule === '2' ? waterHover(record, val) : val
        },
        {
          dataIndex: 'CreateDate',
          title: `${i18n.t('createTime')}／IP`,
          width: 200,
          show:true,
          render: (val: any, { IP }: any) => (
            <Space direction="vertical">
              <div>{common.timeS2L(val)}</div>
              <div>{IP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastLoginDate',
          title: `${i18n.t('lastLoginTime')}／IP`,
          width: 200,
          show:true,
          render: (val: any, { IP }: any) => (
            <Space direction="vertical">
              <div>{common.timeS2L(val)}</div>
              <div>{IP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastDepositTime',
          title: $sc ? i18n.t('lastDepositTime') : `${i18n.t('transferAdd')}／${i18n.t('transferDeduction')}`,
          width: 200,
          show:true,
          render: (val: any, { LastDepositGatewayCode }: any) => (
            <Space direction="vertical">
              <div>{common.timeS2L(val)}</div>
              <div>{i18n.t(LastDepositGatewayCode) || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'CommissionSettleCycle',
          title: i18n.t('rebate'),
          width: 100,
          show: true,
          render: (_: any, record: any) => waterHover(record, i18n.t(REBATE_SETTINGS[record.CommissionIsDefault]))
        },
        {
          dataIndex: 'RemarkFromAgent',
          title: i18n.t('comment'),
          width: 200,
          show:true,
          render: (val: any, { Id }: any) => (
            <Space direction="vertical">
              {val.length > 20 ?
                <Tooltip placement="top" title={val}>
                    <span>
                      {val.split('').map((text: string, i: number) => {
                        let outOf20 = '';
                        if (i <= 20) outOf20 += text
                        return outOf20;
                      })
                      }
                      ...
                    </span>
                </Tooltip> :
                <span>{val || '-'}</span>
              }
              {$p('0103') && <Button
                className="center"
                size="small"
                type="link"
                onClick={() => {
                  setIsEditCommentModal(true);
                  setCurrentMemberId(Id);
                }}
              >
                <Image className="center" src={remarkIcon} preview={false} />
              </Button>}
            </Space>
          )
        },
        {
          title: i18n.t('operation'),
          width: 270,
          show:true,
          render: (_: any, record: any) => (
            <>
              {$p('0102') && <Button style={{ padding: 0 }} className="size-12 text-start" type="link"
                                              onClick={() => navigate({
                                                pathname: generatePath('/team/member-rebate/:id/:account', { id: record.Id, account: record.Account })
                                              })}>
                {i18n.t('edit')}
              </Button>}
              {$p('0104') && <Button style={{ padding: 0 }} className="size-12 text-start" type="link"
                                              onClick={() => {
                                                setIsOpenEditMemberTopUp(true);
                                                setTransferMode(transfer.add);
                                                if (topRef.current) topRef.current.updateMemberData(record);
                                              }}>
                {i18n.t('transferAdd')}
              </Button>}
              {($p('0105') && !$sc) &&
                <Button style={{ padding: 0 }} className="size-12 text-start" type="link"
                        onClick={() => {
                          setIsOpenEditMemberTopUp(true);
                          setTransferMode(transfer.reduce);
                          if (topRef.current) topRef.current.updateMemberData(record);
                        }}>
                  {i18n.t('transferDeduction')}
                </Button>}
              {$p('0106') && <Button style={{ padding: 0 }} className="size-12 text-start" type="link"
                                              onClick={() => {
                                                setIsOpenEditPayRecord(true);
                                                if (childRef.current) childRef.current.updateMemberId(record.Id);
                                              }}>
                {i18n.t('paymentTransactionRecords')}
              </Button>}
              <Button style={{ padding: 0 }} className="size-12 text-start" type="link"
                      onClick={() => navigate({
                        pathname: generatePath('/data/bet-report/:account', { account: record.Account })
                      })}>
                {i18n.t('bettingReports')}
              </Button>
            </>
          )
        },
      ].map((item, i) => ({ ...item, key: i }))
      .filter(item => !($s.CommissionModule === '2' && item.dataIndex === 'CommissionSettleCycle')))
    }
  }, [init, $s]);

  return (
    <>
      <Row justify="end" className="mt--2">
        <Col>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={
              <div className="w-10">
                {columns
                  .filter((item: any) => !item.isOpenMore)
                  .map((item: any) =>
                    <div key={item.key}>
                      <Checkbox
                        checked={item.show}
                        onChange={e => {
                          updateColumnShow(item.dataIndex, e.target.checked)}}
                      >
                        {typeof item.title === 'function' ? (
                          React.Children.map(item.title().props.children[1], (child) =>
                            typeof child === 'object' && child.type === 'span' ? (
                              child.props.children
                            ) : (
                              child
                            )
                          )
                        ) : (
                          item.title
                        )}
                      </Checkbox>
                    </div>
                  )}
              </div>
            }
          >
            <Button><DatabaseFilled /></Button>
          </Popover>
        </Col>
      </Row>
      <Table
        className="mt-1"
        loading={isValidating}
        size="small"
        scroll={{ x: 400 }}
        dataSource={_memberList?.Data?.map((item: any) => ({ ...item, key: item.Id }))}
        columns={ columns
          .filter((item: any) => item.show)}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          total: _memberList?.TotalRecord,
          current: searchParams.PageIndex,
          pageSize: searchParams.PageSize
        }}
        onChange={memberTableChange}
      />

      {/* 新增備註 */}
      <PopupMemberMemoModal currentId={currentMemberId} mutate={mutate}
        isOpen={isEditCommentModal} close={() => setIsEditCommentModal(false)}
      />
      {/* 轉帳添加 */}
      <PopupEditMemberTopUp
        refs={topRef}
        isOpen={isOpenEditMemberTopUp}
        close={() => setIsOpenEditMemberTopUp(false)}
        mode={transferMode}
      />
      <PopupMemberCashLog
        refs={childRef}
        isOpen={isOpenEditPayRecord}
        close={() => setIsOpenEditPayRecord(false)}
      />
    </>
  )
}

// 代理備註
export const PopupAgentMemoModal = ({ isOpen, close, currentAgent, mutate }: any) => {
  const [form] = Form.useForm();
  const handleSubmitComment = async (formData: any) => {
    const send = {
      Id: currentAgent.Id,
      Status: currentAgent.Status,
      Remark: formData.Note,
    };
    $post({
      url: 'api/agent/update',
      send,
      success: () => {
        mutate();
        handleCancel();
        message.success(i18n.t('saveSuccess'));
      }
    })
  };

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('comment')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" loading={false}
            onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmitComment}>
        <Form.Item name="Note" rules={[{ required: true, message: `${i18n.t("inputData")}${i18n.t("comment")}` }]}>
          <Input.TextArea
            size="middle"
            autoSize={{ minRows: 5, maxRows: 5 }}
            maxLength={100}
            showCount
            placeholder={`${i18n.t('inputData')}`}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

// 會員備註
export const PopupMemberMemoModal = ({ isOpen, close, currentId, mutate }: any) => {
  const [form] = Form.useForm();
  const handleSubmitComment = async (formData: any) => {
    const send = {
      OwnerRole: 0,
      OwnerId: currentId,
      Note: formData.Note,
    };
    $post({
      url: 'api/memo/create',
      send,
      success: () => {
        mutate();
        handleCancel();
        message.success(i18n.t('saveSuccess'));
      }
    })
  };

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('comment')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button key="confirm" type="primary" loading={false}
              onClick={form.submit}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmitComment}>
        <Form.Item name="Note" rules={[{ required: true, message: `${i18n.t("inputData")}${i18n.t("comment")}` }]}>
          <Input.TextArea
            size="middle"
            autoSize={{ minRows: 5, maxRows: 5 }}
            maxLength={100}
            showCount
            placeholder={`${i18n.t('inputData')}`}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

// 新增會員
export const PopupAddMemberModal = ({ isOpen, close, mutate }: any) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 送出
  const handleSubmit = async (formData: any) => {
    const send = {
      Account: formData.account,
      Password: formData.password,
      RepeatPassword: formData.password,
      NickName: formData.nickname,
      Status: 0,
      Remark: formData.remark,
    };
    $post({
      url: 'api/member/create',
      send,
      success: () => {
        mutate();
        handleCancel();
        message.success(i18n.t('addSuccess'));
      },
    }, setIsLoading)
    // const response = await PostMemberCreate(send);
    // if (response.State === 'Success') {
    //   mutate();
    //   handleCancel();
    //   message.success(i18n.t('addSuccess'));
    // }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 800);
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button key="confirm" type="primary" loading={isLoading} onClick={form.submit}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('account')} rules={[
              { required: true },
              { min: 4, message: `${i18n.t('within4To12Characters')}` },
              { max: 12, message: `${i18n.t('within4To12Characters')}` },
            ]}>
              <Input placeholder={`${i18n.t('within4To12Characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="password" label={i18n.t('password')} rules={[
              { required: true },
              { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
              { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
            ]}>
              <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="nickname" label={i18n.t('nickname')} rules={[
              { required: true },
              { min: 4, message: `4~10${i18n.t('characters')}` },
              { max: 10, message: `4~10${i18n.t('characters')}` },
            ]}>
              <Input placeholder={`4~10${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label={i18n.t('comment')}>
              <Input.TextArea placeholder={`${i18n.t('inputData')}`} autoSize={{ minRows: 1, maxRows: 1 }} maxLength={100} showCount/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 會員轉帳
export const PopupEditMemberTopUp = ({ isOpen, close, refs, mode }: any) => {
  useImperativeHandle(refs, () => ({

    // 取得列表的會員資料
    updateMemberData(data: any) {
      form.setFieldsValue({
        id: data.Id,
        transferToMember: data.Account,
        amount: '',
        rolloverRate: '',
        remark: ''
      });
    },

  }));

  const { isCashVersion: $sc } = useSite();

  // 取得餘額
  const [agentBalance, setAgentBalance] = useState(0);
  const { data: _agentInfo, mutate: mutateAgent, isValidating: agentValidating } = $get({
    url: 'api/agent/info'
  });
  useEffect(() => {
    if (_agentInfo && isOpen) {
      mutateAgent()
      setAgentBalance(_agentInfo.Data.Balance)
      form.setFieldsValue({
        currentAgentBalance: $f(_agentInfo.Data.Balance)
      });
    }
  }, [_agentInfo, isOpen]);

  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (formData: any) => {
    setLoading(true);
    const positive = mode
    const send = {
      MemberAccount: formData.transferToMember,
      Amount: formData.amount * positive,
      ValidBetMultiple: formData.rolloverRate,
      Remark: formData.remark,
    };
    $post({
      url: 'api/transfer/member',
      send,
      success: () => {
        handleCancel();
        message.success(i18n.t('operationSuccess'));
        setLoading(false);
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('memberBalanceLimitReached'))
        } else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  function checkBalance(e: any) {
    setInsufficientBalance(parseFloat(e.target.value) > parseFloat(agentBalance as unknown as string));
  }

  return (
    <Modal
      title={mode === transfer.add ? i18n.t('transferAdd') : i18n.t('transferDeduction')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12,12]}>
          <Col><Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" onClick={form.submit} disabled={insufficientBalance || loading }>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="id" className="display-none"><Input /></Form.Item>
          <Row gutter={[12, 12]} className="pb-1">
            <Col span={24}>
              <Form.Item name="transferToMember"
                label={mode === transfer.add ? i18n.t('transferToMember') : i18n.t('deductedFromMembers')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount" label={i18n.t('amount')} rules={common.verify({ point: 4, max: 999999999.9999 })}>
                <Input placeholder={`${i18n.t('inputData')}`} onChange={checkBalance} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={common.verify({ point: 0 })}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="currentAgentBalance" label={
                <Row align="middle" gutter={[12, 12]}>
                  <Col>{i18n.t('currentAgentBalance')}</Col>
                  {insufficientBalance && <>
                    <Image className="center" src={hintIcon} width={15} height={15} preview={false} />
                    <Col>{i18n.t('insufficientBalance')}</Col>
                  </>}
                </Row>
              }>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="remark" label={i18n.t('comment')} required rules={[{ required: true }]}>
                <Input.TextArea placeholder={`${i18n.t('inputData')}`} autoSize={{ minRows: 1, maxRows: 1 }} maxLength={100} showCount/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  )
};

// 金流紀錄
export const PopupMemberCashLog = ({ isOpen, close, refs }: any) => {
  useImperativeHandle(refs, () => ({

    updateMemberId(id: any) {
      PaymentSearchParams.MemberId = id;
      setPaymentSearchParams({ ...PaymentSearchParams });
    },

  }));

  const defaultParams = {
    PageSize: 10,
    PageIndex: 1,
  }
  const [PaymentSearchParams, setPaymentSearchParams] = useState<SearchParams>(defaultParams);
  const [memberBalanceList, setMemberBalanceList] = useState<TableAttributes>({});
  const { data: _memberBalanceList, isValidating } = $get({
    url: 'api/member/balance/list',
    params: PaymentSearchParams,
    allow: !!PaymentSearchParams.MemberId
  })
  useEffect(() => {
    if (_memberBalanceList && _memberBalanceList.Data) {
      const data = _memberBalanceList.Data.map((item: any, i: number) => ({
        key: i,
        ...item
      }))
      setMemberBalanceList({
        dataSource: data,
        total: _memberBalanceList.TotalRecord
      })
    } else {
      setMemberBalanceList({
        dataSource: [],
        total: 0
      })
    }
  }, [_memberBalanceList]);

  return (
    <Modal
      title={i18n.t('paymentTransactionRecords')}
      open={isOpen}
      onCancel={close}
      centered
      width={800}
      footer={false}
    >
      <Table
        size="middle"
        scroll={{ x: 400 }}
        loading={isValidating}
        dataSource={memberBalanceList.dataSource}
        columns={[
          {
            dataIndex: 'KeyId',
            title: i18n.t('category'),
            fixed: 'left',
            width: 120,
            render: (val) => MEMBER_LOG[val] ? i18n.t(MEMBER_LOG[val]) : val
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('passTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('transactionAmount'),
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
        ]}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          total: memberBalanceList.total,
        }}
        onChange={(p: any) => handleTableChange(PaymentSearchParams, setPaymentSearchParams, p)}
      />
    </Modal>
  )
}

// 新增代理
export const PopupAddAgentModal = ({ isOpen, close, reload = () => { } }: any) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData: any) => {

    if (!REGEX_ACCOUNT.test(formData.account) || typeof formData.account[0] !== 'string') {
      message.error(i18n.t('INCORRECT_ACCOUNT_FORMAT'));
      return;
    }

    setIsLoading(true);
    const send = {
      Account: formData.account,
      Password: formData.password,
      RepeatPassword: formData.password,
      Status: 0,
      Remark: formData.memo,
    };
    $post({
      url: 'api/agent/create',
      send,
      success: () => {
        handleCancel();
        message.success(i18n.t('addSuccess'));
        reload();
      }
    }, setIsLoading)
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          <Button key="confirm" type="primary" loading={isLoading} onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name="account" label={i18n.t('account')} rules={[
          { required: true },
          { min: 4, message: `${i18n.t('within4To12Characters')}` },
          { max: 12, message: `${i18n.t('within4To12Characters')}` },
        ]}>
          <Input placeholder={`${i18n.t('within4To12Characters')}`} />
        </Form.Item>
        <Form.Item name="password" label={i18n.t('password')} rules={[
          { required: true },
          { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
          { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
        ]}>
          <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
        </Form.Item>
        <Form.Item name="nickname" label={i18n.t('nickname')} rules={[
          { required: true },
          { min: 4, message: `4~10${i18n.t('characters')}` },
          { max: 10, message: `4~10${i18n.t('characters')}` },
        ]}>
          <Input placeholder={`4~10${i18n.t('characters')}`} />
        </Form.Item>
        <Form.Item name="memo" label={i18n.t('comment')}>
          <Input.TextArea placeholder={`${i18n.t('inputData')}`} autoSize={{ minRows: 1, maxRows: 1 }} maxLength={100} showCount/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const MemberLevelSelect = ({ mode, name, form, disabled }: {
  mode?: any
  name: any;
  form: any
  disabled?: boolean;
}) => {

  const { data } = $get({ url: 'api/memberlevel/list' });
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={mode === 'multiple' ? i18n.t('pleaseSelectMemberLevel') : i18n.t('memberLevel')}
      className="w-full"
      options={[
        { value: '', label: i18n.t('all') },
        ...data ? data.Data.map((item: any) => (
          { value: item.Id, label: item.Name }
        )) : []
      ].filter(item => !(mode === 'multiple' && item.value === ''))}
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

// 結算週期下拉表(返水占成報表)
export const SelectCycleDate = ({ setPeriod, mode }: any) => {
  // const [dateList, setDateList] = useState<string[]>([]);
  const { data: commissionList } = $get({
    url: 'api/report/commission/period/list',
    allow: mode === SETTLEMENT.revenue
  })
  const { data: revenueshareList } = $get({
    url: 'api/report/revenueshare/period/list',
    allow: mode === SETTLEMENT.revenueshare
  })

  return (
    <Select
      placeholder={i18n.t('pleaseSelectDate')}
      className="w-full"
      options={(commissionList?.Data || revenueshareList?.Data)?.map((item: any) => ({ value: item.Period }))}
      onChange={setPeriod}
    />
  )
}

// 報表 遊戲類型展開
export const DailyList = ({ loading, scroll, data, columns, reset }: any) => {

  useEffect(() => {
    if (data) {
      setTimeout(init);
    }
  }, [data, reset]);

  const init = () => {
    setAgentId(0);
    setList([]);
  }

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => record.rowExpandable && record.Detail.length > 0,
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setAgentId(record.AgentId);
    setList(record.Detail)
  }

  // 收合
  const backto = (record: any) => {
    init();
  }

  const [list, setList] = useState<any>([]);
  const [agentId, setAgentId] = useState<any>(0);

  return (
    <>
      <Table
        loading={loading}
        size="middle"
        scroll={scroll}
        expandable={{ ...expandableSa, expandedRowKeys: [agentId] }}
        dataSource={
          data?.map((item: any) => ({ key: item.AgentId, rowExpandable: true, ...item }))
            .filter((item: any) => item.key === agentId || !agentId)
        }
        columns={columns}
        pagination={false}
      />
      {!!agentId &&
        <Table
          size="middle"
          scroll={scroll}
          expandable={{ ...expandableSa, expandedRowKeys: [agentId] }}
          dataSource={
            list?.map((item: any) => ({ key: item.AgentId, ...item }))
          }
          columns={columns}
          pagination={false}
        />}
    </>
  )
}
