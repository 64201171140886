import { useEffect, useState } from "react";
import { $get } from "services";
import { COOKIE } from "constants/cookie";
import Cookies from "js-cookie";

// 帳號與權限資料

export interface AccountInfo {
  Id?: number;
  SAId?: number;
  AGId?: number;
  AGsId?: number;

  AGAccount?: string;
  Account?: string;
  Balance?: number;
  Permission?: string;
  Status?: number;

  NickName?: string;
  Email?: string;
  IP?: string;
  IPLocation?: string;
  PhoneNumber?: string;
  PhonePrefix?: string;
  Remark?: string;

  Level?: number;
  DownlineAgentCount?: number;
  DownlineMemberCount?: number;
  InDownlineAgentCount?: number;
  InDownlineMemberCount?: number;
  RefCode?: string;

  CommissionSettleCycle?: number;
  RevenueShareSettleCycle?: number;
  Role?: number;
  TagsId?: string;

  CreateDate?: string;
  UpdateDate?: string;
  VerifiedDate?: string;
  LastLoginDate?: string;
}

const useAccount = () => {
  const [userToken, setUserToken] = useState<string | null | undefined>(Cookies.get(COOKIE.TOKEN));

  useEffect(() => {
    if (!userToken && !window.location.hash.includes('#/login')) {
      window.location.replace('/#/login/UNAUTHORIZATION');
    }
  }, [userToken]);

  // 是否取得資料
  const [init, setInit] = useState<boolean>(false);

  // 代理資訊
  const { data: _agent, mutate } = $get({ url: 'api/agent/info' })
  const [info, setInfo] = useState<AccountInfo>({});
  const [permissionIds, setPermissionIds] = useState<string[]>([]);

  useEffect(() => {
    if (_agent) {
      setInfo(_agent.Data);
      const ids = JSON.parse(_agent.Data.Permission);
      setPermissionIds(ids.concat(['-1']));
    }
  }, [_agent])

  useEffect(() => {
    if (Object.keys(info).length > 0 && permissionIds.includes('-1')) {
      setInit(true);
    }
  }, [info, permissionIds])

  // 權限檢查
  const permissions = (id: string) => {
    return permissionIds.includes(id);
  };

  // 執行登入
  const login = async (token: string) => {
    Cookies.set(COOKIE.TOKEN, token);
    setUserToken(token);
  };

  // 執行登出
  const logout = () => {
    Cookies.remove(COOKIE.TOKEN);
    Cookies.remove(COOKIE.FIRST_LOGIN);
    setUserToken(null);
  };

  return {
    init,
    info,
    permissions,
    login,
    logout,
    mutate
  }
};

export default useAccount;
