import { Button, Col, Image, Modal, Row } from "antd";
import hintIcon from 'assets/image/icon-12.png';
import { TAB } from "enum/state";
import i18n from "i18n";
import { $post } from "services";

// 棄用
export const PopupCloseChatRoom = ({ isOpen, close, onLeaveChatRoom, sessionId, activeTab }: {
  isOpen: boolean;
  close: () => void;
  onLeaveChatRoom: () => void;
  sessionId: string;
  activeTab: string;
}) => {
  const onCloseChat = () => {
    if (activeTab === TAB.客服中心) {
      $post({
        url: 'api/chatroom/service/session/close',
        send: { SessionId: sessionId },
        success: () => onLeaveChatRoom()
      })
    } else {
      onLeaveChatRoom()
    }
  }

  return (
    <Modal
      title={
        <Row gutter={[12, 12]} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      width={350}
      centered
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button onClick={close}>{i18n.t('cancel')}</Button></Col>
          <Col><Button type="primary" onClick={onCloseChat}>
            {i18n.t('confirm')}
          </Button></Col>
        </Row>
      }
    >
      <Row>
        <Col>{i18n.t('endTheCurrentConversation')}?</Col>
      </Row>
    </Modal>
  )
}

export const PopupCloseChatNotify = ({ isOpen, close, onLeaveChatRoom }: {
  isOpen: boolean;
  close: () => void;
  onLeaveChatRoom: () => void;
}) => {
  const onClose = () => {
    onLeaveChatRoom();
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={[12, 12]} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      width={350}
      centered
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('close')}</Button></Col>
        </Row>
      }
    >
      <Row>
        <Col>{i18n.t('currentChatRoomConversationEnded')}</Col>
      </Row>
    </Modal>
  )
}