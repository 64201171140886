import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import { MemberLevelSelect, PopupAddMemberModal, ViewMemberList } from 'components/team.component';
import dayjs from 'dayjs';
import { MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchHistory } from 'slice/searchSlice';
import { RootType } from 'store/configureStore';
import { $mobile, enumToOptions, timeL2S } from 'utils/common';

const PageMain: React.FC = () => {
  const dispatch = useDispatch();

  const { info, permissions: $p } = useAccount();

  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);
  const [isOpenAddMemberModal, setIsOpenAddMemberModal] = useState(false);
  const refMemberList = useRef<any>();

  // 搜尋儲存
  const { member: searchHistory } = useSelector((state: RootType) => state.Search);

  const [form] = Form.useForm();
  const defaultDate: string = "0";
  const [timeMode, setTimeMode] = useState<string>(defaultDate);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    PageIndex: 1,
    PageSize: 10,
    LastLoginStartDate: timeL2S(date[0]),
    LastLoginEndDate: timeL2S(date[1]),
  });

  // 按下搜尋後填入或選擇的資料會存入searchMember與searchParams
  const search = (formData: any) => {
    const obj: any = {
      MemberAccount: formData.MemberAccount,
      Status: formData.Status,
      IsFreeze: formData.IsFreeze ? (formData.IsFreeze === MEMBER_FREEZE.normal ? 0 : 1) : '',
      LevelId: formData.LevelId,
      PageIndex: 1,
      PageSize: searchParams.PageSize,
    }

    switch (formData.TimeMode) {
      case "0":
        obj.LastLoginStartDate = timeL2S(date[0])
        obj.LastLoginEndDate = timeL2S(date[1])
        break;
      case "1":
        obj.CreateStartDate = timeL2S(date[0])
        obj.CreateEndDate = timeL2S(date[1])
        break;
    }
    setSearchParams(obj);
    dispatch(setSearchHistory({ member: obj }));
    refMemberList.current?.mutate();
  }

  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  }

  const handleTableChange = (pagination: any) => {
    const param = searchHistory || searchParams
    setSearchParams({ ...param, PageIndex: pagination.current, PageSize: pagination.pageSize })
    dispatch(setSearchHistory({ member: { ...param, PageIndex: pagination.current, PageSize: pagination.pageSize } }));
  }

  const searchSpan = $mobile ? 12 : 6

  return (
    <>
      <LayoutNav />
      <Content>

        {/* 搜尋區 */}
        <Form className='form-search' form={form} onFinish={search} initialValues={{
          TimeMode: '0'
        }}>
          {/* 條件 */}
          <Row gutter={[12, 12]} style={{ maxWidth: '800px' }}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={searchSpan}>
                  <Form.Item name="MemberAccount">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                {/*狀態欄*/}
                <Col span={searchSpan}>
                  <Form.Item name="IsFreeze">
                    <Select
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...enumToOptions(MEMBER_FREEZE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                {/*審核欄*/}
                <Col span={searchSpan}>
                  <Form.Item name="Status">
                    <Select
                      placeholder={i18n.t('audit')}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...enumToOptions(MEMBER_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={$mobile ? searchSpan * 2 : searchSpan}>
                  <Form.Item name="TimeMode">
                    <Select
                      options={[
                        { value: "0", label: `${i18n.t('lastLoginTime')}` },
                        { value: "1", label: `${i18n.t('createTime')}` },
                        { value: "2", label: `${i18n.t('noTimeLimit')}` },
                      ]}
                      onChange={setTimeMode}
                    />
                  </Form.Item>
                </Col>
                {/*開始與結束日期時間*/}
                <DatePickerCol span={$mobile ? searchSpan * 2 : searchSpan}
                  initDate={initDate} date={date} setDate={setDate} displayCom={['Picker']} disabled={timeMode === "2"} />
              </Row>
            </Col>
            <Col span={24}>
              <Row justify={$mobile ? 'end' : 'start'}>
                {/*查詢與清除按鈕*/}
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col>
                      <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                    </Col>
                    <Col>
                      <Button onClick={onClear}>{i18n.t('clear')}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        {/*新增按鈕*/}
        <Row justify={'start'} gutter={[12, 12]} className='mt-1'>
          {$p('0102') &&
            <Col>
              <Button type="primary" onClick={() => setIsOpenAddMemberModal(true)}>{i18n.t('add')}</Button>
            </Col>}
        </Row>

        <ViewMemberList searchParams={searchHistory || searchParams} refs={refMemberList}
          memberTableChange={handleTableChange} />

        <PopupAddMemberModal isOpen={isOpenAddMemberModal} close={() => setIsOpenAddMemberModal(false)} mutate={refMemberList.current?.mutate} />
      </Content>
    </>
  );
};

export default PageMain;