import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, Descriptions, Form, Input, Popover, Row, Select, Table, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav, LayoutTabMember } from "components/layout.component";
import { REBATE_SETTINGS } from "enum/state";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { $get, $post } from "services";
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, verify, convertedToPercentage02 as $p2n } from 'utils/common';

// 編輯會員返水

enum ENABLE {
  disable = 0,
  enabled = 1
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: $s, isCashVersion: $sc } = useSite();

  // 取得代理返水(最大值)
  const { data: agentOpp } = $get({ 
    url: 'api/agent/opp' 
  });
  // 取得代理批次設定會員返水(預設值)
  const { data: defaultOpp } = $get({ 
    url: 'api/member/operationparams/info' 
  });
  // 取得會員返水
  const { data: memberOpp, isValidating } = $get({
    url: 'api/member/commission/info',
    params: { MemberId: id }
  })
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    if (memberOpp && agentOpp) {
      setData([
        {
          key: 1,
          game: i18n.t('EGAMES'),
          name: 'EGamesCommissionRate',
          rebate: memberOpp.Data.EGamesCommissionRate,
          agentRebate: agentOpp.Data.EGamesCommissionRate
        },
        {
          key: 2,
          game: i18n.t('LIVE'),
          name: 'LiveCommissionRate',
          rebate: memberOpp.Data.LiveCommissionRate,
          agentRebate: agentOpp.Data.LiveCommissionRate
        },
        {
          key: 3,
          game: i18n.t('SPORTS'),
          name: 'SportsCommissionRate',
          rebate: memberOpp.Data.SportsCommissionRate,
          agentRebate: agentOpp.Data.SportsCommissionRate
        },
        {
          key: 4,
          game: i18n.t('ESPORTS'),
          name: 'ESportsCommissionRate',
          rebate: memberOpp.Data.ESportsCommissionRate,
          agentRebate: agentOpp.Data.ESportsCommissionRate
        },
        {
          key: 5,
          game: i18n.t('POKER'),
          name: 'PokerCommissionRate',
          rebate: memberOpp.Data.PokerCommissionRate,
          agentRebate: agentOpp.Data.PokerCommissionRate
        },
        {
          key: 6,
          game: i18n.t('FISHING'),
          name: 'FishingCommissionRate',
          rebate: memberOpp.Data.FishingCommissionRate,
          agentRebate: agentOpp.Data.FishingCommissionRate
        },
        {
          key: 7,
          game: i18n.t('COCKFIGHT'),
          name: 'CockfightCommissionRate',
          rebate: memberOpp.Data.CockfightCommissionRate,
          agentRebate: agentOpp.Data.CockfightCommissionRate
        },
        {
          key: 8,
          game: i18n.t('LOTTERY'),
          name: 'LotteryCommissionRate',
          rebate: memberOpp.Data.LotteryCommissionRate,
          agentRebate: agentOpp.Data.LotteryCommissionRate
        },
        {
          key: 9,
          game: i18n.t('SLOT'),
          name: 'SlotCommissionRate',
          rebate: memberOpp.Data.SlotCommissionRate,
          agentRebate: agentOpp.Data.SlotCommissionRate
        }
      ])
    }
    if (memberOpp) {
      form.setFieldsValue({
        CommissionSettleCycle: memberOpp.Data.CommissionSettleCycle,
        IsDefault: memberOpp.Data.IsDefault,
        EGamesCommissionRate: $g(memberOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(memberOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(memberOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(memberOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(memberOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(memberOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(memberOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(memberOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(memberOpp.Data.SlotCommissionRate)
      })
      // setStatusDisabled(memberOpp.Data.Status === ENABLE.disable)
    }
  }, [memberOpp, agentOpp]);

  const handleSetAllRebate = (value: string) => {
    if (common.decimalNumber(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const [isAllDisabled, setIsAllDisabled] = useState(false);
  // const [statusDisabled, setStatusDisabled] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const save = async (formData: any) => {
    const IsDefault = (
      parseFloat(formData.EGamesCommissionRate).toFixed(2) === $g(defaultOpp.Data.EGamesCommissionRate) &&
      parseFloat(formData.LiveCommissionRate).toFixed(2) === $g(defaultOpp.Data.LiveCommissionRate) &&
      parseFloat(formData.SportsCommissionRate).toFixed(2) === $g(defaultOpp.Data.SportsCommissionRate) &&
      parseFloat(formData.ESportsCommissionRate).toFixed(2) === $g(defaultOpp.Data.ESportsCommissionRate) &&
      parseFloat(formData.PokerCommissionRate).toFixed(2) === $g(defaultOpp.Data.PokerCommissionRate) &&
      parseFloat(formData.FishingCommissionRate).toFixed(2) === $g(defaultOpp.Data.FishingCommissionRate) &&
      parseFloat(formData.CockfightCommissionRate).toFixed(2) === $g(defaultOpp.Data.CockfightCommissionRate) &&
      parseFloat(formData.LotteryCommissionRate).toFixed(2) === $g(defaultOpp.Data.LotteryCommissionRate) &&
      parseFloat(formData.SlotCommissionRate).toFixed(2) === $g(defaultOpp.Data.SlotCommissionRate)
    ) ? REBATE_SETTINGS.default : REBATE_SETTINGS.customization

    form.setFieldValue('IsDefault', IsDefault);

    const send = {
      MemberId: id,
      IsDefault,
      EGamesCommissionRate: parseFloat($p2n(formData.EGamesCommissionRate)),
      SlotCommissionRate: parseFloat($p2n(formData.SlotCommissionRate)),
      LiveCommissionRate: parseFloat($p2n(formData.LiveCommissionRate)),
      SportsCommissionRate: parseFloat($p2n(formData.SportsCommissionRate)),
      ESportsCommissionRate: parseFloat($p2n(formData.ESportsCommissionRate)),
      PokerCommissionRate: parseFloat($p2n(formData.PokerCommissionRate)),
      FishingCommissionRate: parseFloat($p2n(formData.FishingCommissionRate)),
      CockfightCommissionRate: parseFloat($p2n(formData.CockfightCommissionRate)),
      LotteryCommissionRate: parseFloat($p2n(formData.LotteryCommissionRate)),
    };
    $post({
      url: 'api/member/commission/update',
      send,
      success: () => {
        message.success(i18n.t('saveSuccess'));
        setIsAllDisabled(true);
        setIsOpenPopover(false);
      }
    })
  }

  const update2Default = (val: any) => {
    if (val === REBATE_SETTINGS.default && defaultOpp) {
      form.setFieldsValue({
        EGamesCommissionRate: $g(defaultOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(defaultOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(defaultOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(defaultOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(defaultOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(defaultOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(defaultOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(defaultOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(defaultOpp.Data.SlotCommissionRate)
      })
    }
  }

  const disable = $s.CommissionModule === '2';

  return (
    <>
      <LayoutNav />
      <Content>
        <LayoutTabMember activeKey="1" />
        <Form form={form} onFinish={save}>
          <Row gutter={[12, 12]}>
            {/* 取消確認 */}
            <Col span={24} className="flex-end">
              {(disable || isAllDisabled)
                ? <Button onClick={() => navigate('/team')}>{i18n.t('close')}</Button>
                : <>
                  <Button onClick={() => navigate('/team')} className="mr-1">{i18n.t('cancel')}</Button>
                  <Button htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                </>
              }
            </Col>
            {/* 返水週期 */}
            <Col span={14}>
              <Descriptions layout={"vertical"} column={4} size="small" bordered>
                {/* 返水週期 */}
                <Descriptions.Item style={{ width: '25%' }} label={
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>
                      <><span className="require">*</span>{i18n.t('rebatePeriod')}</>
                    </Col>
                    <Col>
                      <Tooltip placement="top" className="size-12" title={i18n.t('sameAsUpperAgent')}>
                        <InfoCircleFilled className="center" />
                      </Tooltip>
                    </Col>
                  </Row>
                }>
                  <Form.Item name="CommissionSettleCycle">
                    <Select
                      style={{ width: '100%' }}
                      disabled
                      options={[
                        { value: 0, label: i18n.t('dailyStatement') },
                        { value: 1, label: i18n.t('weeklyStatement') },
                        { value: 2, label: i18n.t('monthlySettlement') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
                {/* 返水設定 */}
                {!($s.CommissionModule === '2') && <Descriptions.Item style={{ width: '25%' }} label={<><span className="require">*</span>{i18n.t('rebateSettings')}</>}>
                  <Form.Item name="IsDefault">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={isAllDisabled}
                      options={common.enumToOptions(REBATE_SETTINGS)}
                      onChange={update2Default}
                    />
                  </Form.Item>
                </Descriptions.Item>}
                {/* 更新人員 */}
                <Descriptions.Item style={{ width: '25%' }} label={<><span className="require">*</span>{i18n.t('updateStaff')}</>}>
                  {memberOpp?.Data.Operator || '-'}
                </Descriptions.Item>
                {/* 更新時間 */}
                <Descriptions.Item style={{ width: '25%' }} label={<><span className="require">*</span>{i18n.t('updateTime')}</>}>
                  {memberOpp?.Data.UpdateDate}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {/* 批次設定 */}
            {!disable && <Col span={24} className="text-end">
              <Popover placement="bottomLeft" trigger="click" open={isOpenPopover} content={
                <Row gutter={[12, 12]}>
                  <Col span={24} className="w-6">
                    <Form.Item name="batchSetting">
                      <Input placeholder={`${i18n.t('inputData')}`}
                        onChange={(e) => handleSetAllRebate(e.target.value)} onPressEnter={() => setIsOpenPopover(false)} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="center">
                    <Button size="small" onClick={() => setIsOpenPopover(false)}>
                      {i18n.t('close')}
                    </Button>
                  </Col>
                </Row>
              }>
                <Button type="primary" disabled={isAllDisabled}
                  onClick={() => setIsOpenPopover(!isOpenPopover)}>
                  {i18n.t('batchSetting')}
                </Button>
              </Popover>
            </Col>}
            {/* 遊戲大類 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                bordered
                className={disable ? 'pt-1' : ''}
                size="small"
                dataSource={data}
                columns={[
                  {
                    dataIndex: 'game',
                    title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                    className: 'color-03',
                    width: '25%',
                    render: (val, record) => disable ? `${val} %` : `${val} % (max：${$g(record.agentRebate)})`
                  },
                  {
                    title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                    width: '25%',
                    render: (_, record) => (
                      <Form.Item name={record.name} rules={verify({ max: disable ? null : $g(record.agentRebate) })}>
                        <Input name={record.name} className="size-12" 
                          disabled={disable || isAllDisabled} defaultValue={common.convertedToPercentage02(record.rebate)} />
                      </Form.Item>
                    )
                  },
                ]}
                pagination={false}
              />
            </Col >
          </Row>
        </Form>
      </Content>
    </>
  )
}

export default PageMain;