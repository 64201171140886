import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, Descriptions, Form, Input, Popover, Row, Select, Table, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav } from "components/layout.component";
import useAccount from "hooks/account.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get, $post } from "services";
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, verify, convertedToPercentage02 as $p2n } from 'utils/common';

// 會員返水設定

export enum ENABLE {
  disable = 0,
  enabled = 1
}

const PageMain: React.FC = () => {
  const { info, permissions: $p } = useAccount();
  const [form] = useForm();

  // 取得代理的返水
  const { data: agentOpp } = $get({ 
    url: 'api/agent/opp'
  });

  // 取得會員預設的返水
  const { data: memberOpp, isValidating } = $get({ 
    url: 'api/member/operationparams/info'
  });

  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    if (agentOpp && memberOpp) {
      setData([
        {
          key: 1,
          game: i18n.t('EGAMES'),
          name: 'EGamesCommissionRate',
          rebate: memberOpp.Data.EGamesCommissionRate,
          agentRebate: agentOpp.Data.EGamesCommissionRate
        },
        {
          key: 2,
          game: i18n.t('LIVE'),
          name: 'LiveCommissionRate',
          rebate: memberOpp.Data.LiveCommissionRate,
          agentRebate: agentOpp.Data.LiveCommissionRate
        },
        {
          key: 3,
          game: i18n.t('SPORTS'),
          name: 'SportsCommissionRate',
          rebate: memberOpp.Data.SportsCommissionRate,
          agentRebate: agentOpp.Data.SportsCommissionRate
        },
        {
          key: 4,
          game: i18n.t('ESPORTS'),
          name: 'ESportsCommissionRate',
          rebate: memberOpp.Data.ESportsCommissionRate,
          agentRebate: agentOpp.Data.ESportsCommissionRate
        },
        {
          key: 5,
          game: i18n.t('POKER'),
          name: 'PokerCommissionRate',
          rebate: memberOpp.Data.PokerCommissionRate,
          agentRebate: agentOpp.Data.PokerCommissionRate
        },
        {
          key: 6,
          game: i18n.t('FISHING'),
          name: 'FishingCommissionRate',
          rebate: memberOpp.Data.FishingCommissionRate,
          agentRebate: agentOpp.Data.FishingCommissionRate
        },
        {
          key: 7,
          game: i18n.t('COCKFIGHT'),
          name: 'CockfightCommissionRate',
          rebate: memberOpp.Data.CockfightCommissionRate,
          agentRebate: agentOpp.Data.CockfightCommissionRate
        },
        {
          key: 8,
          game: i18n.t('LOTTERY'),
          name: 'LotteryCommissionRate',
          rebate: memberOpp.Data.LotteryCommissionRate,
          agentRebate: agentOpp.Data.LotteryCommissionRate
        },
        {
          key: 9,
          game: i18n.t('SLOT'),
          name: 'SlotCommissionRate',
          rebate: memberOpp.Data.SlotCommissionRate,
          agentRebate: agentOpp.Data.SlotCommissionRate
        }
      ])

      cancel();
    }
  }, [agentOpp, memberOpp]);

  const handleSetAllRebate = (value: string) => {
    if (common.decimalNumber(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const [isEdit, setIsEdit] = useState(false);
  // 狀態禁用
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const save = async (formData: any) => {
    const send = {
      Status: formData.Status,
      EGamesCommissionRate: parseFloat($p2n(formData.EGamesCommissionRate)),
      SlotCommissionRate: parseFloat($p2n(formData.SlotCommissionRate)),
      LiveCommissionRate: parseFloat($p2n(formData.LiveCommissionRate)),
      SportsCommissionRate: parseFloat($p2n(formData.SportsCommissionRate)),
      ESportsCommissionRate: parseFloat($p2n(formData.ESportsCommissionRate)),
      PokerCommissionRate: parseFloat($p2n(formData.PokerCommissionRate)),
      FishingCommissionRate: parseFloat($p2n(formData.FishingCommissionRate)),
      CockfightCommissionRate: parseFloat($p2n(formData.CockfightCommissionRate)),
      LotteryCommissionRate: parseFloat($p2n(formData.LotteryCommissionRate)),
    };
    $post({
      url: 'api/member/operationparams/update',
      send,
      success: () => {
        message.success(i18n.t('saveSuccess'));
        setIsEdit(false);
        setIsOpenPopover(false);
      }
    })
  }

  const cancel = () => {
    form.setFieldsValue({
      CommissionSettleCycle: memberOpp.Data.CommissionSettleCycle,
      Status: memberOpp.Data.Status,
      EGamesCommissionRate: $g(memberOpp.Data.EGamesCommissionRate),
      LiveCommissionRate: $g(memberOpp.Data.LiveCommissionRate),
      SportsCommissionRate: $g(memberOpp.Data.SportsCommissionRate),
      ESportsCommissionRate: $g(memberOpp.Data.ESportsCommissionRate),
      PokerCommissionRate: $g(memberOpp.Data.PokerCommissionRate),
      FishingCommissionRate: $g(memberOpp.Data.FishingCommissionRate),
      CockfightCommissionRate: $g(memberOpp.Data.CockfightCommissionRate),
      LotteryCommissionRate: $g(memberOpp.Data.LotteryCommissionRate),
      SlotCommissionRate: $g(memberOpp.Data.SlotCommissionRate)
    })
    setStatusDisabled(memberOpp.Data.Status === ENABLE.disable);
    setIsEdit(false);
  }

  return (
    <>
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={save}>
          <Row gutter={[12, 12]} className="pt-1">
            {/* 取消確認 */}
            <Col span={24} className="flex-end">
              {!isEdit
                ? $p('0402') ? <Button onClick={() => setIsEdit(true)} type="primary">{i18n.t('edit')}</Button> : <></>
                : <>
                  <Button onClick={cancel} className="mr-1">{i18n.t('cancel')}</Button>
                  <Button htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                </>
              }
            </Col>
            {/* 返水週期 */}
            <Col span={14}>
              <Descriptions layout={"vertical"} column={3} size="small" bordered>
                <Descriptions.Item style={{ width: '33.33%' }} label={
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>
                      <><span className="require">*</span>{i18n.t('rebatePeriod')}</>
                    </Col>
                    <Col>
                      <Tooltip placement="top" className="size-12" title={i18n.t('sameAsUpperAgent')}>
                        <InfoCircleFilled className="center" />
                      </Tooltip>
                    </Col>
                  </Row>
                }>
                  <Form.Item name="CommissionSettleCycle">
                    <Select
                      style={{ width: '100%' }}
                      disabled
                      options={[
                        { value: 0, label: i18n.t('dailyStatement') },
                        { value: 1, label: i18n.t('weeklyStatement') },
                        { value: 2, label: i18n.t('monthlySettlement') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item style={{ width: '33.33%' }} label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Form.Item name="Status" rules={[{ required: true, message: `${i18n.t("required")}` }]}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={!isEdit}
                      options={common.enumToOptions(ENABLE)}
                      onChange={val => setStatusDisabled(val === ENABLE.disable)}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item style={{ width: '33.33%' }} label={<><span className="require">*</span>{i18n.t('updateTime')}</>}>
                  {agentOpp?.Data.UpdateDate}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {/* 批次設定 */}
            <Col span={24} className="text-end">
              <Popover placement="bottomLeft" trigger="click" open={isOpenPopover} content={
                <Row gutter={[12, 12]}>
                  <Col span={24} className="w-6">
                    <Form.Item name="batchSetting">
                      <Input placeholder={`${i18n.t('inputData')}`}
                        onChange={(e) => handleSetAllRebate(e.target.value)} onPressEnter={() => setIsOpenPopover(false)} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="center">
                    <Button size="small" onClick={() => setIsOpenPopover(false)}>
                      {i18n.t('close')}
                    </Button>
                  </Col>
                </Row>
              }>
                <Button type="primary" disabled={!isEdit}
                  onClick={() => setIsOpenPopover(!isOpenPopover)}>
                  {i18n.t('batchSetting')}
                </Button>
              </Popover>
            </Col>
            {/* 遊戲大類 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                bordered
                size="small"
                dataSource={data}
                columns={[
                  {
                    dataIndex: 'game',
                    title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                    className: 'color-03',
                    width: '25%',
                    render: (val, record) => `${val} % (max：${$g(record.agentRebate)})`
                  },
                  {
                    title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                    width: '25%',
                    render: (_, record) => (
                      <Form.Item name={record.name} rules={verify({ max: $g(record.agentRebate) })}>
                        <Input name={record.name} className="size-12" disabled={!isEdit || statusDisabled} defaultValue={common.convertedToPercentage02(record.rebate)} />
                      </Form.Item>
                    )
                  },
                ]}
                pagination={false}
              />
            </Col >
          </Row>
        </Form>
      </Content>
    </>
  )
}

export default PageMain;