import { Button, Col, Descriptions, Form, Input, Popover, Row, Select, Spin, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, SelectAgentPermission } from 'components/layout.component';
import { AGENT_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { 
  decimalNumber as $cc,
  toFormatNumber as $f,
  convertedToPercentage01 as $g,
  convertedToPercentage02 as $p2n,
  $mobile,
  enumToOptions,
  verify
} from 'utils/common';

// 編輯代理

const PageMain: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [editDone, setEditDone] = useState(false);

  // 取得這代資料
  const { data: _agentInfo, mutate, isValidating: agentValidating } = $get({
    url: 'api/agent/info',
    params: {
      AgentId: id
    },
    allow: !!id
  })
  useEffect(() => {
    if (_agentInfo) {
      baseDataForm.setFieldsValue({
        account: _agentInfo.Data.Account,
        password: '',
        confirmNewPassword: '',
        status: _agentInfo.Data.Status,
        remark: _agentInfo.Data.Remark,
      });
    }
  }, [_agentInfo]);

  // 取得上代返水(最大值)
  const [parentAgentOpp, setParentAgentOpp] = useState<any>({
    BonusCostRate: 100,
    CashFlowFeeRate: 100,

    EGamesCommissionRate: 100,
    LiveCommissionRate: 100,
    SportsCommissionRate: 100,
    ESportsCommissionRate: 100,
    PokerCommissionRate: 100,
    FishingCommissionRate: 100,
    CockfightCommissionRate: 100,
    LotteryCommissionRate: 100,
    SlotCommissionRate: 100,

    EGamesRevenueShareRate: 100,
    LiveRevenueShareRate: 100,
    SportsRevenueShareRate: 100,
    ESportsRevenueShareRate: 100,
    PokerRevenueShareRate: 100,
    FishingRevenueShareRate: 100,
    CockfightRevenueShareRate: 100,
    LotteryRevenueShareRate: 100,
    SlotRevenueShareRate: 100,
  });
  const { data: _parentAgentOpp } = $get({
    url: 'api/agent/opp',
    params: {
      AgentId: _agentInfo?.Data?.AGId
    },
    allow: !!_agentInfo?.Data?.AGId && _agentInfo?.Data?.Level !== 0
  })
  useEffect(() => {
    if (_parentAgentOpp) {
      setParentAgentOpp({
        BonusCostRate: 100,
        CashFlowFeeRate: 100,

        EGamesCommissionRate: $g(_parentAgentOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(_parentAgentOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(_parentAgentOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(_parentAgentOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(_parentAgentOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(_parentAgentOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(_parentAgentOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(_parentAgentOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(_parentAgentOpp.Data.SlotCommissionRate),

        EGamesRevenueShareRate: $g(_parentAgentOpp.Data.EGamesRevenueShareRate),
        LiveRevenueShareRate: $g(_parentAgentOpp.Data.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(_parentAgentOpp.Data.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(_parentAgentOpp.Data.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(_parentAgentOpp.Data.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(_parentAgentOpp.Data.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(_parentAgentOpp.Data.LiveRevenueShareRate),
        LotteryRevenueShareRate: $g(_parentAgentOpp.Data.LotteryRevenueShareRate),
        SlotRevenueShareRate: $g(_parentAgentOpp.Data.SlotRevenueShareRate),
      })
    }
  }, [_parentAgentOpp]);

  // 取得這代返水
  const { data: _agentOpp, isValidating } = $get({
    url: 'api/agent/opp',
    params: { AgentId: id }
  })
  useEffect(() => {
    if (_agentOpp) {
      rateForm.setFieldsValue({
        EGamesCommissionRate: $g(_agentOpp.Data.EGamesCommissionRate),
        SlotCommissionRate: $g(_agentOpp.Data.SlotCommissionRate),
        LiveCommissionRate: $g(_agentOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(_agentOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(_agentOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(_agentOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(_agentOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(_agentOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(_agentOpp.Data.LotteryCommissionRate),

        EGamesRevenueShareRate: $g(_agentOpp.Data.EGamesRevenueShareRate),
        SlotRevenueShareRate: $g(_agentOpp.Data.SlotRevenueShareRate),
        LiveRevenueShareRate: $g(_agentOpp.Data.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(_agentOpp.Data.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(_agentOpp.Data.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(_agentOpp.Data.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(_agentOpp.Data.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(_agentOpp.Data.CockfightRevenueShareRate),
        LotteryRevenueShareRate: $g(_agentOpp.Data.LotteryRevenueShareRate)
      });
    }
  }, [_agentOpp]);

  const save = () => {
    baseDataSubmit();
    rebateSubmit();
  }

  // 送出基本資料
  const [permissions, setPermissions] = useState([]);
  const [baseDataForm] = Form.useForm();
  const baseDataSubmit = async () => {
    const formData = baseDataForm.getFieldsValue();
    const send = {
      Id: id,
      Status: formData.status,
      Remark: formData.remark,
      Permission: permissions,
      NewPassword: formData.password
    };
    $post({
      url: 'api/agent/update',
      send,
      success: () => {
        mutate();
        message.success(i18n.t('saveSuccess'));
        setEditDone(true);
      }
    })
  }

  // 送出返水資料
  const [rateForm] = Form.useForm();
  const rebateSubmit = async () => {
    const formData = rateForm.getFieldsValue();
    const send = {
      AgentId: id,
      EGamesCommissionRate: parseFloat($p2n(formData['EGamesCommissionRate'])),
      SlotCommissionRate: parseFloat($p2n(formData['SlotCommissionRate'])),
      LiveCommissionRate: parseFloat($p2n(formData['LiveCommissionRate'])),
      SportsCommissionRate: parseFloat($p2n(formData['SportsCommissionRate'])),
      ESportsCommissionRate: parseFloat($p2n(formData['ESportsCommissionRate'])),
      PokerCommissionRate: parseFloat($p2n(formData['PokerCommissionRate'])),
      FishingCommissionRate: parseFloat($p2n(formData['FishingCommissionRate'])),
      CockfightCommissionRate: parseFloat($p2n(formData['CockfightCommissionRate'])),
      LotteryCommissionRate: parseFloat($p2n(formData['LotteryCommissionRate'])),

      EGamesRevenueShareRate: parseFloat($p2n(formData['EGamesRevenueShareRate'])),
      SlotRevenueShareRate: parseFloat($p2n(formData['SlotRevenueShareRate'])),
      LiveRevenueShareRate: parseFloat($p2n(formData['LiveRevenueShareRate'])),
      SportsRevenueShareRate: parseFloat($p2n(formData['SportsRevenueShareRate'])),
      ESportsRevenueShareRate: parseFloat($p2n(formData['ESportsRevenueShareRate'])),
      PokerRevenueShareRate: parseFloat($p2n(formData['PokerRevenueShareRate'])),
      FishingRevenueShareRate: parseFloat($p2n(formData['FishingRevenueShareRate'])),
      CockfightRevenueShareRate: parseFloat($p2n(formData['CockfightRevenueShareRate'])),
      LotteryRevenueShareRate: parseFloat($p2n(formData['LotteryRevenueShareRate'])),
    };
    $post({
      url: 'api/agent/opp/update',
      send,
      success: () => {

      }
    })
  }

  const [rebatePopover, setRebatePopover] = useState(false);
  const [commissionPopover, setCommissionPopover] = useState(false);

  const handleSetAllRebate = (value: string) => {
    if ($cc(value)) {
      rateForm.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const handleSetAllShareRate = (value: string) => {
    if ($cc(value)) {
      rateForm.setFieldsValue({
        SlotRevenueShareRate: value,
        EGamesRevenueShareRate: value,
        LiveRevenueShareRate: value,
        SportsRevenueShareRate: value,
        ESportsRevenueShareRate: value,
        PokerRevenueShareRate: value,
        FishingRevenueShareRate: value,
        CockfightRevenueShareRate: value,
        LotteryRevenueShareRate: value
      })
    }
  }

  return (
    <>
      <LayoutNav />
      <Content>

        <Row align="middle" className="mt-1" gutter={[0, 18]}>
          {/* 基本資料 */}
          <Col span={24}>
            <Spin spinning={agentValidating}>
              <Form layout="horizontal" form={baseDataForm} onFinish={save}>
                <Descriptions size="small" layout="horizontal" bordered column={$mobile ? 1 : 2}
                  labelStyle={{ fontSize: 12, fontWeight: 500, width: '25%' }}
                  contentStyle={{ width: $mobile ? '' : '25%' }}
                  title={
                    <Row align="middle" justify="space-between">
                      <Col className="size-16 font-w-md">{i18n.t('edit')}</Col>
                      <Col>
                        <Row align="middle" gutter={[12, 12]}>
                          {editDone
                            ?
                            <Col><Button onClick={() => navigate('/team/agent')}>{i18n.t('close')}</Button></Col>
                            :
                            <>
                              <Col><Button onClick={() => navigate('/team/agent')}>{i18n.t('cancel')}</Button></Col>
                              <Col><Button type="primary" onClick={baseDataForm.submit}>{i18n.t('confirm')}</Button></Col>
                            </>
                          }
                        </Row>
                      </Col>
                    </Row>
                  }>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('account')}</>}>
                    <Form.Item name="account">
                      <Input placeholder={`${i18n.t('inputData')}`} disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{i18n.t('password')}</>}>
                    <Form.Item name="password" rules={[
                      { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
                      { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
                    ]}>
                      <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} disabled={editDone} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="status" className="w-full">
                      <Select
                        disabled
                        options={[
                          { value: 0, label: i18n.t('all') },
                          ...enumToOptions(AGENT_STATE)
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('comment')}>
                    <Form.Item name="remark" >
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={editDone} maxLength={100} showCount/>
                    </Form.Item>
                  </Descriptions.Item>
                  {/* <Descriptions.Item label=""><></></Descriptions.Item>
                  <Descriptions.Item label=""><></></Descriptions.Item> */}
                </Descriptions>
              </Form>
            </Spin>
          </Col>

          {/* 後台權限 */}
          <Col span={24}>
            <Spin spinning={agentValidating}>
              <Col className="size-16 font-w-md">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <SelectAgentPermission agent={_agentInfo?.Data} permissions={permissions} setPermissions={setPermissions} confirm={editDone} />
              </Col>
            </Spin>
          </Col>

          {/* 返水設定 */}
          <Col span={24}>
            <Form layout="vertical" form={rateForm}>
              <Col span={24} className="size-16 font-w-md">{i18n.t('cashbackCommissionSetting')}</Col>
              <Col span={24} className="mt-1">
                <Spin spinning={isValidating}>
                  <Row align="middle" justify={'end'} gutter={[0, 18]}>
                    {/* 返水 */}
                    <Col>
                      <Popover placement="bottomLeft" trigger="click" open={rebatePopover} content={
                        <Row gutter={[12, 12]}>
                          <Col span={24} className="w-6">
                            <Input placeholder={`${i18n.t('inputData')}`} onChange={(e) => handleSetAllRebate(e.target.value)} disabled={editDone} />
                          </Col>
                          <Col span={24} className="center">
                            <Button size="small" onClick={() => setRebatePopover(false)} disabled={editDone}>
                              {i18n.t('close')}
                            </Button>
                          </Col>
                        </Row>
                      }>
                        <Button type="primary" onClick={() => setRebatePopover(!rebatePopover)}>{i18n.t('batchSetting')}</Button>
                      </Popover>
                    </Col>
                    <Col span={24}>
                      <Table
                        bordered
                        size="middle"
                        columns={[
                          {
                            title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                            dataIndex: 'game',
                            className: 'color-03',
                            width: '50%',
                            render: (_, { game, name }) => `${game} % (max：${$f(parentAgentOpp[name], 2)})`
                          },
                          {
                            title: <><span className="require">*</span>{`${i18n.t('rebateRate')} % `}</>,
                            dataIndex: 'rebate',
                            width: '50%',
                            render: (_, { name }) => (
                              <Form.Item name={name} rules={verify({ max: $f(parentAgentOpp[name], 2) })}>
                                <Input className="size-12" disabled={editDone} />
                              </Form.Item>
                            )
                          },
                        ]}
                        dataSource={[
                          {
                            game: i18n.t('EGAMES'),
                            name: 'EGamesCommissionRate',
                          },
                          {
                            game: i18n.t('LIVE'),
                            name: 'LiveCommissionRate',
                          },
                          {
                            game: i18n.t('SPORTS'),
                            name: 'SportsCommissionRate',
                          },
                          {
                            game: i18n.t('ESPORTS'),
                            name: 'ESportsCommissionRate',
                          },
                          {
                            game: i18n.t('POKER'),
                            name: 'PokerCommissionRate',
                          },
                          {
                            game: i18n.t('FISHING'),
                            name: 'FishingCommissionRate',
                          },
                          {
                            game: i18n.t('COCKFIGHT'),
                            name: 'CockfightCommissionRate',
                          },
                          {
                            game: i18n.t('LOTTERY'),
                            name: 'LotteryCommissionRate',
                          },
                          {
                            game: i18n.t('SLOT'),
                            name: 'SlotCommissionRate',
                          }
                        ]}
                        pagination={false}
                      />
                    </Col>
                    {/* 佔成 */}
                    <Col>
                      <Popover placement="bottomLeft" trigger="click" open={commissionPopover} content={
                        <Row gutter={[12, 12]}>
                          <Col span={24} className="w-6">
                            <Input placeholder={`${i18n.t('inputData')}`} onChange={(e) => handleSetAllShareRate(e.target.value)} disabled={editDone} />
                          </Col>
                          <Col span={24} className="center">
                            <Button size="small" onClick={() => setCommissionPopover(false)} disabled={editDone}>
                              {i18n.t('close')}
                            </Button>
                          </Col>
                        </Row>
                      }>
                        <Button type="primary" onClick={() => setCommissionPopover(!commissionPopover)}>{i18n.t('batchSetting')}</Button>
                      </Popover>
                    </Col>
                    <Col span={24}>
                      <Table
                        bordered
                        size="middle"
                        columns={[
                          {
                            title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                            dataIndex: 'game',
                            className: 'color-03',
                            width: '50%',
                            render: (_, { game, name }) => `${game} % (max：${$f(parentAgentOpp[name], 2)})`
                          },
                          {
                            title: <><span className="require">*</span>{`${i18n.t('revenueRate')} % `}</>,
                            dataIndex: 'rebate',
                            width: '50%',
                            render: (_, { name }) => (
                              <Form.Item name={name} rules={verify({ max: $f(parentAgentOpp[name], 2) })}>
                                <Input className="size-12" disabled={editDone} />
                              </Form.Item>
                            )
                          },
                        ]}
                        dataSource={[
                          {
                            game: i18n.t('EGAMES'),
                            name: 'EGamesRevenueShareRate',
                          },
                          {
                            game: i18n.t('LIVE'),
                            name: 'LiveRevenueShareRate',
                          },
                          {
                            game: i18n.t('SPORTS'),
                            name: 'SportsRevenueShareRate',
                          },
                          {
                            game: i18n.t('ESPORTS'),
                            name: 'ESportsRevenueShareRate',
                          },
                          {
                            game: i18n.t('POKER'),
                            name: 'PokerRevenueShareRate',
                          },
                          {
                            game: i18n.t('FISHING'),
                            name: 'FishingRevenueShareRate',
                          },
                          {
                            game: i18n.t('COCKFIGHT'),
                            name: 'CockfightRevenueShareRate',
                          },
                          {
                            game: i18n.t('LOTTERY'),
                            name: 'LotteryRevenueShareRate',
                          },
                          {
                            game: i18n.t('SLOT'),
                            name: 'SlotRevenueShareRate',
                          }
                        ]}
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Form>
          </Col>
        </Row>

      </Content>
    </>
  );
};

export default PageMain;