export enum MEMBER_STATE {
  waitApproval = 0,
  approvaled = 1,
}
export enum MEMBER_FREEZE {
  normal = 2,
  frozen = 3,
}
export enum AGENT_STATE {
  waitApproval = 0,
  normal = 1,
  frozen = 2,
}
export enum TRANSFER_TYPE {
  addBalance = 1,
  deductBalance = 2,
}
export enum AGENT_RELATION {
  pageTitleChatroom = 1,
  subAgent = 2,
}
export enum SETTLEMENT {
  revenue = 1,
  revenueshare = 2
}
export enum REBATE_SETTINGS {
  default = 1,
  customization = 0,
}

export enum transfer {
  add = 1,
  reduce = -1
}
export enum STATE {
  pass = 1,
  reject = 2,
  padding = 3
}

export enum WITHDRAW_STATE {
  pendingServiceReview = 0,
  pendingSupervisorReview = 1,
  pendingFinancialReview = 2,
  financialReview = 9,
  processing = 3,
  paymentTerminalWaiting = 4,
  approvaled = 5,
  fail = 6,
  reject = -2
}
export enum MEMBER_BANK_STATE {
  verificationFailed = -1,
  waitVerify = 0,
  verificationSuccessfulOpen = 1,
  verificationSuccessfulClose = 2,
  delete = 9
}

export enum CHECKBOX_DISPLAY {
  空 = 0,
  方塊 = 2,
  打勾 = 3,
}
export enum TAB {
  上層代理 = '1',
  下層代理 = '2',
  下層會員 = '3',
  客服中心 = '4'
}

export enum ENABLE {
  disabled = 0,
  enabled = 1
}
