import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";

import tw from 'locales/zh-TW.json';
import cn from 'locales/zh-CN.json';
import us from 'locales/en-US.json';
import vn from 'locales/vi-VN.json';

const resources = {
  "zh-TW": { translation: tw },
  "zh-CN": { translation: cn },
  "en-US": { translation: us },
  "vi-VN": { translation: vn },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Cookies.get('lang') || "en-US" || document.documentElement.lang,
  interpolation: {
    escapeValue: false,
  },
  debug: false,
}); 

export default i18n;
