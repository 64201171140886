import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Row, Select, Spin, Tabs, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import defaultAvatarIcon from 'assets/image/avatar-default.png';
import waitingServiceContactIcon from 'assets/image/support-service-wait.gif';
import uploadPhotoMessageIcon from 'assets/image/uploadPhotoMessage.svg';
import { PopupCloseChatNotify } from 'components/chat.component';
import { COOKIE } from 'constants/cookie';
import { SOCKET_ON } from 'constants/socket';
import { useSocket } from 'contexts/socket.context';
import dayjs from 'dayjs';
import { TAB } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { ChatList, MessageList, SystemMessage } from "react-chat-elements";
import { Helmet } from 'react-helmet';
import { $get, $post } from 'services';
import { timeS2LUnix, transferTimeFormat } from 'utils/common';
import { InquiryWindow } from "components/layout.component";

const { TabPane } = Tabs;

enum ROLE {
  訪客 = -1,
  會員 = 0,
  代理 = 1,
  總代理 = 3,
  客服 = 2,
}

enum MESSAGE_TYPE {
  文字 = 0,
  圖片 = 1
}

const PageMain: React.FC = () => {
  const { data: $s } = useSite();
  const { init, info, permissions: $p } = useAccount();
  const socket = useSocket();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceSessionId, setServiceSessionId] = useState<string>('');
  const [memberSessionId, setMemberSessionId] = useState<string>('');
  const [agSessionId, setAgSessionId] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [isContact, setIsContact] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState(TAB.下層代理);
  useEffect(() => {
    if (info.AGAccount !== undefined && info.Account !== undefined) {
      const newActiveTab = (info.AGAccount !== info.Account && info.AGAccount !== 'company') ? TAB.上層代理 : TAB.下層代理;
      setActiveTab(newActiveTab);
    }
  }, [init]);
  const [openChat, setOpenChat] = useState(false);
  const [listPage, setListPage] = useState<number[]>([1, 10]);
  const messageListReferance = useRef()
  const [page, setPage] = useState<number[]>([1, 10]);
  const [serviceName, setServiceName] = useState<string>('');
  const [showDisplayContent, setShowDisplayContent] = useState('');
  const [nowDate, setNowDate] = useState('');
  const [account, setAccount] = useState('');
  const [isShowCloseChatModal, setIsShowCloseChatModal] = useState(false);

  // 聊天室(手機版)
  document.body.style.maxWidth = '100vw';
  // document.body.style.minHeight = '100vh'
  var PaneWidth = document.body.clientWidth / 4;

  // 聊天室設定
  const { data: ChatSettingInfo } = $get({ url: 'api/chatroom/setting' })
  // socket接收聊天訊息
  const [serviceMessage, setServiceMessage] = useState<any>([]);
  const [saMessage, setSaMessage] = useState<any[]>([]);
  const [agMessage, setAgMessage] = useState<any[]>([]);
  const [memberMessage, setMemberMessage] = useState<any>([]);
  const { isOurService } = useSite();
  useEffect(() => {
    if (socket.serviceMessages) {
      const service = socket.serviceMessages.SessionId === sessionId;
      if (service) {
        setShowBackToBottom(true);
        setServiceName(socket.serviceMessages.SenderAccount);
        setShowDisplayContent('');
        setServiceMessage([
          ...serviceMessage,
          ...[socket.serviceMessages].map((item: ChatMessageInfo) => ({
            position: item.SenderRole === ROLE.客服 ? 'left' : 'right',
            type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
            avatar: item.SenderRole === ROLE.客服 && (item.SenderAvatar || ChatSettingInfo?.Data?.DefaultAvatar),
            title: item.SenderAccount,
            text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
            date: timeS2LUnix(item.SendTime),
            dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            }
          }))
        ])
      }
    }
    if (socket.privateMessage) {
      const SAmessages = socket.privateMessage.SessionId === sessionId;
      const AGmessages = socket.privateMessage.SessionId === agSessionId;
      const MemberMessages = socket.privateMessage.SessionId === memberSessionId;
      if (SAmessages) {
        setShowBackToBottom(true);
        setSaMessage([
          ...saMessage,
          ...[socket.privateMessage].map((item: ChatMessageInfo) => ({
            position: item.SenderRole === ROLE.代理 && item.SenderId !== info.Id ? 'left' : 'right',
            type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
            avatar: item.SenderRole === ROLE.代理 ? defaultAvatarIcon : '',
            title: item.AgentAccount,
            text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
            date: timeS2LUnix(item.SendTime),
            dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            }
          }))
        ])
      }
      if (AGmessages) {
        setShowBackToBottom(true);
        setAgMessage([
          ...agMessage,
          ...[socket.privateMessage].map((item: ChatMessageInfo) => ({
            position: item.AgentAccount === info.Account ? 'right' : 'left',
            type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
            avatar: item.SenderRole !== ROLE.代理 ? defaultAvatarIcon : '',
            title: item.AgentAccount,
            text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
            date: timeS2LUnix(item.SendTime),
            dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            }
          }))
        ])
      }
      if (MemberMessages) {
        setShowBackToBottom(true);
        setMemberMessage([
          ...memberMessage,
          ...[socket.privateMessage].map((item: ChatMessageInfo) => ({
            position: item.SenderRole === ROLE.代理 ? 'right' : 'left',
            type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
            avatar: item.SenderRole !== ROLE.代理 ? defaultAvatarIcon : '',
            title: item.SenderRole === ROLE.代理 ? item.AgentAccount : item.MemberAccount,
            text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
            date: timeS2LUnix(item.SendTime),
            dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            }
          }))
        ])
      }
    }
  }, [socket.serviceMessages, socket.privateMessage]);
  // socket 收到各類通知
  useEffect(() => {
    const n = socket?.notification?.split('-')[0];
    switch (n) {
      case SOCKET_ON.建立客服服務:
        break;
      case SOCKET_ON.客服接聽服務:
        const serviceSession = socket.notificationData.SessionId;
        setServiceSessionId(serviceSession);
        setIsContact(serviceSession === sessionId);
        serviceSession === sessionId && setShowDisplayContent(`${i18n.t('customerServiceAnswered')}`);
        break;
      case SOCKET_ON.客服轉接服務:
        break;
      case SOCKET_ON.客服結束對話:
        const session = socket.notificationData.SessionId;
        if (isContact && session === sessionId) {
          setShowDisplayContent(`${i18n.t('currentConversationEnded')}`);
          setIsContact(false);
          setIsShowCloseChatModal(true);
        }
        break;
    }
  }, [socket.notification, sessionId]);
  // 監聽有無接聽客服
  useEffect(() => {
    if (!sessionId && !serviceSessionId && sessionId !== serviceSessionId) {
      setIsContact(false);
    }
  }, [sessionId, serviceSessionId]);
  // 上層代理訊息列表及內容
  const {
    data: ChatSA,
    isValidating: ChatSAListLoading,
    mutate: RefreshChatSAList
  } = $get({
    url: 'api/chatroom/superioragent/session/list'
  })
  const {
    data: ChatSAMessage,
    isValidating: ChatSAMessageLoading,
  } = $get({
    url: 'api/chatroom/superioragent/message/list',
    params: {
      Time: nowDate,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!sessionId && activeTab !== TAB.客服中心
  })
  useEffect(() => {
    if (ChatSAMessage) {
      setSaMessage([
        ...ChatSAMessage.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          ))
          .map((item: ChatMessageInfo) => (
            {
              position: item.SenderRole === ROLE.代理 &&
                item.SenderId !== info.Id ? 'left' : 'right',
              type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
              avatar: item.SenderRole === ROLE.代理 ? defaultAvatarIcon : '',
              title: item.AgentAccount,
              text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
              date: timeS2LUnix(item.SendTime),
              dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              }
            }
          )),
        ...saMessage
      ])
    }
  }, [ChatSAMessage]);
  // 下層代理訊息列表及內容
  const {
    data: ChatAG,
    isValidating: ChatAGListLoading,
    mutate: RefreshChatAGList
  } = $get({
    url: 'api/chatroom/inferioragent/session/list',
    params: {
      PageIndex: listPage[0],
      PageSize: listPage[1]
    }
  })
  const {
    data: ChatAgMessage,
    isValidating: ChatAgMessageLoading,
  } = $get({
    url: 'api/chatroom/inferioragent/message/list',
    params: {
      SessionId: agSessionId,
      Time: nowDate,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!agSessionId
  })
  useEffect(() => {
    if (ChatAgMessage) {
      setAgMessage([
        ...ChatAgMessage.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          ))
          .map((item: ChatMessageInfo) => (
            {
              position: item.SenderRole === ROLE.代理 && item.SenderId === info.Id ? 'right' : 'left',
              type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
              avatar: item.SenderRole !== ROLE.代理 ? defaultAvatarIcon : '',
              title: item.AgentAccount,
              text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
              date: timeS2LUnix(item.SendTime),
              dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              }
            }
          ))
      ])
    }
  }, [ChatAgMessage]);
  // 下層會員訊息列表及內容
  const [memberChat, setMemberChat] = useState([]);
  const {
    data: ChatMember,
    isValidating: ChatMemberListLoading,
    mutate: RefreshChatMemberList
  } = $get({ url: 'api/chatroom/inferiormember/session/list' })
  const {
    data: ChatMemberMessage,
    isValidating: ChatMemberMessageLoading,
  } = $get({
    url: 'api/chatroom/inferiormember/message/list',
    params: {
      SessionId: memberSessionId,
      Time: nowDate,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!memberSessionId
  })
  useEffect(() => {
    if (ChatMemberMessage) {
      setMemberMessage([
        ...ChatMemberMessage.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          ))
          .map((item: ChatMessageInfo) => (
            {
              position: item.SenderRole === ROLE.代理 ? 'right' : 'left',
              type: item.Type === MESSAGE_TYPE.文字 ? 'text' : 'photo',
              avatar: item.SenderRole !== ROLE.代理 ? defaultAvatarIcon : '',
              title: item.SenderRole === ROLE.代理 ? item.AgentAccount : item.MemberAccount,
              text: item.Type === MESSAGE_TYPE.文字 ? item.Content : '',
              date: timeS2LUnix(item.SendTime),
              dateString: transferTimeFormat(timeS2LUnix(item.SendTime) as number),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              }
            }
          ))
      ])
    }
  }, [ChatMemberMessage]);
  useEffect(() => {
    if (ChatMember) {
      setMemberChat(ChatMember?.Data);
    }
  }, [ChatMember]);
  // 搜尋下層會員列表
  const onSearchMember = (value: string) => {
    if (value) {
      setMemberChat(
        ChatMember?.Data?.filter((item: ChatInfo) => item.DownAccount === value)
      )
    }
  }
  // 點擊Tab觸發事件
  const onActiveTab = (e: string) => {
    switch (e) {
      case TAB.上層代理:
        setActiveTab(TAB.上層代理);
        setOpenChat(false);
        break;
      case TAB.下層代理:
        setActiveTab(TAB.下層代理);
        setOpenChat(false);
        break;
      case TAB.下層會員:
        setActiveTab(TAB.下層會員);
        setOpenChat(false);
        break;
      case TAB.客服中心:
        $post({
          url: 'api/chatroom/service/session/create',
          success: (response: any) => {
            setActiveTab(TAB.客服中心);
            setOpenChat(true);
            setSessionId(response.SessionId);
            message.success(i18n.t('connectionSuccess'));
          }
        })
        break;
    }
    setSaMessage([]);
    setAgMessage([]);
    setServiceMessage([]);
    setMemberMessage([]);
  }
  // 點擊聊天室
  const handleOpenChat = async (account?: string, sessionId?: string, type?: TAB) => {
    account && setAccount(account);
    setNowDate(dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
    setPage([1, 10]);
    switch (type) {
      case TAB.上層代理:
        setSessionId(sessionId as string);
        break;
      case TAB.下層代理:
        setAgSessionId(sessionId as string);
        break;
      case TAB.下層會員:
        setMemberSessionId(sessionId as string);
        break;
    }
    $post({
      url: 'api/chatroom/private/message/read',
      send: { SessionId: sessionId },
      success: () => setOpenChat(true)
    })
  };
  // 結束對話
  const [prevActiveTab, setPrevActiveTab] = useState(
    info.AGAccount !== info.Account && info.AGAccount !== 'company'
      ? TAB.上層代理
      : TAB.下層代理
  );
  const onCloseChat = (type: TAB) => {
    setIsOpenModal(true);
    switch (type) {
      case TAB.上層代理:
        setPrevActiveTab(TAB.上層代理);
        break;
      case TAB.下層代理:
        setPrevActiveTab(TAB.下層代理);
        break;
      case TAB.下層會員:
        setPrevActiveTab(TAB.下層會員);
        break;
      case TAB.客服中心:
        setPrevActiveTab(
          (info.AGAccount !== info.Account && info.AGAccount !== 'company')
            ? TAB.上層代理
            : TAB.下層代理
        );
        break;
    }
  }
  // 確認離開客聊天室
  const onLeaveChatRoom = () => {
    setActiveTab(prevActiveTab);
    setIsOpenModal(false);
    setServiceName('');
    setOpenChat(false);
    setPage([1, 10]);
    setServiceMessage([]);
    setSaMessage([]);
    setAgMessage([]);
    setMemberMessage([]);
    setInputValue('');
    setSessionId('');
    setAgSessionId('');
    setMemberSessionId('');
    setShowDisplayContent('');
    setShowBackToBottom(false);
    setIsContact(false);
  }
  // 聊天室狀態訊息及發送訊息
  const [sendType, setSendType] = useState(MESSAGE_TYPE.文字);
  const [inputValue, setInputValue] = useState('');
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const chatWindowRef = useRef<HTMLDivElement | null>(null);
  // const listWindowsRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, 300);
    }
  }, [openChat]);
  const handleScroll = (reloadAtTop?: boolean) => {
    // const listWindow = listWindowsRef.current;
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      const isAtBottom =
        chatWindow.scrollTop + chatWindow.clientHeight === chatWindow.scrollHeight;
      const isAtTop = chatWindow.scrollTop === 0;
      if (isAtBottom) {
        setShowBackToBottom(false);
      }
      if (isAtTop && reloadAtTop) {
        setPage([1, page[1] + 10]);
      }
    }
    // if (listWindow) {
    //   const isAtBottom =
    //     listWindow.scrollTop + listWindow.clientHeight === listWindow.scrollHeight;
    //   if (isAtBottom && reloadAtTop) {
    //     setListPage([1, page[1] + 10]);
    //   }
    // }
  }
  const scrollToBottom = (delay = 300) => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTo({
          top: chatWindow.scrollHeight,
        });
      }, delay);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isEnterPressed) {
        switch (activeTab) {
          case TAB.上層代理:
            onSendSA();
            break;
          case TAB.下層代理:
            onSendAG();
            break;
          case TAB.下層會員:
            onSendMember();
            break;
          case TAB.客服中心:
            onSend();
            break;
        }
        setIsEnterPressed(false);
      } else {
        setIsEnterPressed(true);
        setTimeout(() => {
          setIsEnterPressed(false);
        }, 300);
      }
    }
  };
  // 發訊息給客服
  const onSend = () => {
    if (inputValue) {
      $post({
        url: 'api/chatroom/service/message/send',
        send: {
          SessionId: sessionId,
          Type: sendType,
          Content: inputValue
        },
        success: (response: any) => {
          setInputValue('');
          scrollToBottom();
          setFileList([]);
          setSendType(MESSAGE_TYPE.文字);
          form.setFieldsValue({
            fileList: []
          })
        }
      }, setIsLoading)
    }
  }
  // 發訊息給上層代理
  const onSendSA = () => {
    if (inputValue) {
      $post({
        url: 'api/chatroom/superioragent/message/send',
        send: {
          Type: sendType,
          Content: inputValue
        },
        success: () => {
          setInputValue('');
          scrollToBottom();
          setFileList([]);
          setSendType(MESSAGE_TYPE.文字);
          form.setFieldsValue({
            fileList: []
          })
          RefreshChatSAList();
        }
      }, setIsLoading)
    }
  }
  // 發訊息給下層代理
  const onSendAG = async () => {
    if (inputValue) {
      $post({
        url: 'api/chatroom/inferioragent/message/send',
        send: {
          SessionId: agSessionId,
          Type: sendType,
          Content: inputValue
        },
        success: () => {
          setInputValue('');
          scrollToBottom();
          setFileList([]);
          setSendType(MESSAGE_TYPE.文字);
          form.setFieldsValue({
            fileList: []
          })
          RefreshChatAGList();
        }
      }, setIsLoading)
    }
  }
  // 發訊息給下層會員
  const onSendMember = async () => {
    if (inputValue) {
      $post({
        url: 'api/chatroom/inferiormember/message/send',
        send: {
          SessionId: memberSessionId,
          Type: sendType,
          Content: inputValue
        },
        success: () => {
          setInputValue('');
          scrollToBottom();
          setFileList([]);
          setSendType(MESSAGE_TYPE.文字);
          form.setFieldsValue({
            fileList: []
          })
          RefreshChatMemberList();
        }
      }, setIsLoading)
    }
  }
  // 上傳圖片
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const handleFileListChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsLoading(true);
    setInputValue('');
    const { fileList: newFileList } = info;
    const newFormData = new FormData();

    newFileList.forEach((file) => {
      newFormData.append('Media', file.originFileObj as RcFile);
    });

    form.setFieldsValue({
      fileList: newFileList
    })

    setFileList(newFileList);

    const upload = await fetch(`/api/upload/images/public/chatroom`, {
      headers: {
        "Authorization": Cookies.get(COOKIE.TOKEN) as any
      },
      method: 'POST',
      body: newFormData,
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => data)
    setInputValue(upload.Data[0]);
    setSendType(MESSAGE_TYPE.圖片);
    setIsLoading(false);
  };

  // 關閉瀏覽器客服離線
  useEffect(() => {
    if (activeTab === TAB.客服中心) {
      window.onbeforeunload = () => {
        $post({
          url: 'api/chatroom/service/session/close',
          send: { SessionId: sessionId },
        })
      }
    }
  }, [sessionId]);

  const handleSubmit = () => {
    if (activeTab === TAB.客服中心) {
      $post({
        url: 'api/chatroom/service/session/close',
        send: { SessionId: sessionId },
        success: () => onLeaveChatRoom()
      })
    } else {
      onLeaveChatRoom()
    }
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Form form={form}>
        <Tabs centered={Cookies.get('lang') === 'zh-TW' || Cookies.get('lang') === 'zh-CN'} tabBarStyle={{ marginBottom: '0px' }} indicatorSize={PaneWidth} activeKey={activeTab} onChange={onActiveTab}>
          {info.AGAccount !== info.Account && info.AGAccount !== 'company' &&
          <TabPane key={TAB.上層代理} tab={i18n.t('pageTitleChatroom')} disabled={openChat}>
            <Spin spinning={ChatSAListLoading}>
              {
                ChatSA && !openChat &&
                ChatSA?.Data?.map((item: ChatInfo, i: number) => (
                  <ChatList
                    key={i}
                    {...(item as any)}
                    className='chat-list'
                    onClick={() => handleOpenChat(item.UpAccount, item.Id, TAB.上層代理)}
                    dataSource={[
                      {
                        id: item.Id,
                        avatar: defaultAvatarIcon,
                        title: item.UpAccount,
                        subtitle: item.LatestMessageType === MESSAGE_TYPE.文字 ? item.LatestMessage : i18n.t('sentAnImage'),
                        date: timeS2LUnix(item.LatestMessageTime),
                        dateString: transferTimeFormat(timeS2LUnix(item.LatestMessageTime) as number),
                        className: 'selected-chat',
                        unread: item.InferiorUnreadCount
                      }
                    ]}
                  />
                ))
              }
            </Spin>
            {
              openChat && activeTab === TAB.上層代理 &&
              <>
                <Card
                  bodyStyle={{
                    height: 550,
                    padding: '10px 0px',
                    background: '#F9F9F9'
                  }}
                  title={
                    <Row align="middle" justify="space-between">
                      <Col style={{ cursor: 'pointer' }} onClick={() => onCloseChat(TAB.上層代理)}>
                        <LeftOutlined />
                        <span style={{
                          display: 'inline-block',
                          marginBottom: '1px',
                          marginLeft: 5
                        }}>
                          {account}</span>
                      </Col>
                    </Row>
                  }
                >
                  <Spin spinning={ChatSAMessageLoading}>
                    <div ref={chatWindowRef}
                      style={{
                        height: 540,
                        minHeight: 540,
                        maxHeight: 540,
                        overflowY: 'auto',
                        scrollBehavior: 'auto',
                        position: 'relative'
                      }} onScroll={() => handleScroll(true)}>
                      <MessageList
                        className='message-list'
                        referance={messageListReferance}
                        dataSource={saMessage}
                        lockable={true}
                        downButton={true}
                        downButtonBadge={10}
                        sendMessagePreview={true}
                      />
                      <div style={{
                        display: `${showBackToBottom ? '' : 'none'}`,
                        textAlign: 'center',
                        position: 'sticky',
                        bottom: 5,
                      }}>
                        <div style={{
                          display: 'inline-block',
                          padding: '2px 5px',
                          background: '#e3e3e3',
                          border: '.5px solid #e3e3e3',
                          borderRadius: 8,
                          cursor: 'pointer'
                        }} onClick={() => scrollToBottom(0)}>
                          {i18n.t('newMessage')}
                        </div>
                      </div>
                    </div>
                  </Spin>
                </Card>
                <Row>
                  <Col span={24}>
                    <TextArea
                      maxLength={100}
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      placeholder={`${i18n.t('inputData')}`}
                      bordered={false}
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      onKeyDown={e => handleKeyDown(e)}
                      disabled={isLoading}
                    />
                  </Col>
                  <Col span={24}>
                    <Row align="middle" justify="space-between">
                      <Col span={6} className="ml-1">
                        <Form.Item name="fileList" valuePropName="fileList">
                          <ImgCrop
                            aspect={1}
                            showGrid
                            showReset
                            resetText={`${i18n.t('reset')}`}
                            modalTitle={`${i18n.t('editImage')}`}
                          >
                            <Upload
                              multiple
                              disabled={isLoading}
                              accept=".jpg, .png"
                              maxCount={1}
                              fileList={fileList}
                              onChange={handleFileListChange}
                              onRemove={() => {
                                setFileList([]);
                                setInputValue('');
                                setSendType(MESSAGE_TYPE.文字);
                              }}
                            >
                              <Image style={{ cursor: 'pointer' }} src={uploadPhotoMessageIcon} preview={false} />
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Button type="primary" onClick={onSendSA} loading={isLoading}>{i18n.t('send')}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
          </TabPane>}
          <TabPane key={TAB.下層代理} tab={i18n.t('subAgent')} disabled={openChat}>
            <Spin spinning={ChatAGListLoading}>
              {
                ChatAG?.Data?.length === 0 &&
                <div>{i18n.t('currentlyNoSubordinateAgentToChat')}</div>
              }
              {
                ChatAG && !openChat &&
                ChatAG?.Data?.map((item: ChatInfo, i: number) => (
                  <ChatList
                    key={i}
                    {...(item as any)}
                    className='chat-list'
                    onClick={() => handleOpenChat(item.DownAccount, item.Id, TAB.下層代理)}
                    dataSource={[
                      {
                        id: item.Id,
                        avatar: defaultAvatarIcon,
                        title: item.DownAccount,
                        subtitle: item.LatestMessageType === MESSAGE_TYPE.文字 ? item.LatestMessage : i18n.t('sentAnImage'),
                        date: timeS2LUnix(item.LatestMessageTime),
                        dateString: transferTimeFormat(timeS2LUnix(item.LatestMessageTime) as number),
                        className: 'selected-chat',
                        unread: item.SuperiorUnreadCount
                      }
                    ]}
                  />
                ))
              }
            </Spin>
            {
              openChat && activeTab === TAB.下層代理 &&
              <>
                <Card
                  bodyStyle={{
                    height: 550,
                    padding: '10px 0px',
                    background: '#F9F9F9'
                  }}
                  title={
                    <Row align="middle" justify="space-between">
                      <Col style={{ cursor: 'pointer' }} onClick={() => onCloseChat(TAB.下層代理)}>
                        <LeftOutlined />
                        <span style={{
                          display: 'inline-block',
                          marginBottom: '1px',
                          marginLeft: 5
                        }}>
                          {account}</span>
                      </Col>
                    </Row>
                  }
                >
                  <Spin spinning={ChatAgMessageLoading}>
                    <div ref={chatWindowRef}
                      style={{
                        height: 540,
                        minHeight: 540,
                        maxHeight: 540,
                        overflowY: 'auto',
                        scrollBehavior: 'auto',
                        position: 'relative'
                      }} onScroll={() => handleScroll(true)}>
                      <MessageList
                        className='message-list'
                        referance={messageListReferance}
                        dataSource={agMessage}
                        lockable={true}
                        downButton={true}
                        downButtonBadge={10}
                        sendMessagePreview={true}
                      />
                      <div style={{
                        display: `${showBackToBottom ? '' : 'none'}`,
                        textAlign: 'center',
                        position: 'sticky',
                        bottom: 5,
                      }}>
                        <div style={{
                          display: 'inline-block',
                          padding: '2px 5px',
                          background: '#e3e3e3',
                          border: '.5px solid #e3e3e3',
                          borderRadius: 8,
                          cursor: 'pointer'
                        }} onClick={() => scrollToBottom(0)}>
                          {i18n.t('newMessage')}
                        </div>
                      </div>
                    </div>
                  </Spin>
                </Card>
                <Row>
                  <Col span={24}>
                    <TextArea
                      maxLength={100}
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      placeholder={`${i18n.t('inputData')}`}
                      bordered={false}
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      onKeyDown={e => handleKeyDown(e)}
                      disabled={isLoading}
                    />
                  </Col>
                  <Col span={24}>
                    <Row align="middle" justify="space-between">
                      <Col span={6} className="ml-1">
                        <Form.Item name="fileList" valuePropName="fileList">
                          <ImgCrop
                            aspect={1}
                            showGrid
                            showReset
                            resetText={`${i18n.t('reset')}`}
                            modalTitle={`${i18n.t('editImage')}`}
                          >
                            <Upload
                              multiple
                              disabled={isLoading}
                              accept=".jpg, .png"
                              maxCount={1}
                              fileList={fileList}
                              onChange={handleFileListChange}
                              onRemove={() => {
                                setFileList([]);
                                setInputValue('');
                                setSendType(MESSAGE_TYPE.文字);
                              }}
                            >
                              <Image style={{ cursor: 'pointer' }} src={uploadPhotoMessageIcon} preview={false} />
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Button type="primary" onClick={onSendAG} loading={isLoading}>{i18n.t('send')}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
          </TabPane>
          <TabPane key={TAB.下層會員} tab={i18n.t('pageTitleChatroomUnderMember')} disabled={openChat}>
            {
              ChatMember?.Data?.length === 0
                ? <div>{i18n.t('currentlyNoSubordinateMembersToChat')}</div>
                : (
                  <>
                    {
                      !openChat &&
                      <Select className="w-full"
                        placeholder={`${i18n.t("inputData")}${i18n.t("account")}`}
                        showSearch
                        allowClear
                        onSelect={e => onSearchMember(e)}
                        onClear={() => setMemberChat(ChatMember?.Data)}
                        options={
                          ChatMember ?
                            ChatMember?.Data?.map((item: ChatInfo) => (
                              { value: item.DownAccount, label: item.DownAccount }
                            )) : []
                        }
                      />
                    }
                  </>
                )
            }
            <Spin spinning={ChatMemberListLoading}>
              {
                memberChat && !openChat &&
                memberChat.map((item: ChatInfo, i: number) => (
                  <ChatList
                    key={i}
                    {...(item as any)}
                    className='chat-list'
                    onClick={() => handleOpenChat(item.DownAccount, item.Id, TAB.下層會員)}
                    dataSource={[
                      {
                        id: item.Id,
                        avatar: defaultAvatarIcon,
                        title: item.DownAccount,
                        subtitle: item.LatestMessageType === MESSAGE_TYPE.文字 ? item.LatestMessage : i18n.t('sentAnImage'),
                        date: timeS2LUnix(item.LatestMessageTime),
                        dateString: transferTimeFormat(timeS2LUnix(item.LatestMessageTime) as number),
                        className: 'selected-chat',
                        unread: item.SuperiorUnreadCount
                      }
                    ]}
                  />
                ))
              }
            </Spin>
            {
              openChat && activeTab === TAB.下層會員 &&
              <>
                <Card
                  bodyStyle={{
                    height: 550,
                    padding: '10px 0px',
                    background: '#F9F9F9'
                  }}
                  title={
                    <Row align="middle" justify="space-between">
                      <Col style={{ cursor: 'pointer' }} onClick={() => onCloseChat(TAB.下層會員)}>
                        <LeftOutlined />
                        <span style={{
                          display: 'inline-block',
                          marginBottom: '1px',
                          marginLeft: 5
                        }}>
                          {account}</span>
                      </Col>
                    </Row>
                  }
                >
                  <Spin spinning={ChatMemberMessageLoading}>
                    <div ref={chatWindowRef}
                      style={{
                        height: 540,
                        minHeight: 540,
                        maxHeight: 540,
                        overflowY: 'auto',
                        scrollBehavior: 'auto',
                        position: 'relative'
                      }} onScroll={() => handleScroll(true)}>
                      <MessageList
                        className='message-list'
                        referance={messageListReferance}
                        dataSource={memberMessage}
                        lockable={true}
                        downButton={true}
                        downButtonBadge={10}
                        sendMessagePreview={true}
                      />
                      <div style={{
                        display: `${showBackToBottom ? '' : 'none'}`,
                        textAlign: 'center',
                        position: 'sticky',
                        bottom: 5,
                      }}>
                        <div style={{
                          display: 'inline-block',
                          padding: '2px 5px',
                          background: '#e3e3e3',
                          border: '.5px solid #e3e3e3',
                          borderRadius: 8,
                          cursor: 'pointer'
                        }} onClick={() => scrollToBottom(0)}>
                          {i18n.t('newMessage')}
                        </div>
                      </div>
                    </div>
                  </Spin>
                </Card>
                <Row>
                  <Col span={24}>
                    <TextArea
                      maxLength={100}
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      placeholder={`${i18n.t('inputData')}`}
                      bordered={false}
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      onKeyDown={e => handleKeyDown(e)}
                      disabled={isLoading}
                    />
                  </Col>
                  <Col span={24}>
                    <Row align="middle" justify="space-between">
                      <Col span={6} className="ml-1">
                        <Form.Item name="fileList" valuePropName="fileList">
                          <ImgCrop
                            aspect={1}
                            showGrid
                            showReset
                            resetText={`${i18n.t('reset')}`}
                            modalTitle={`${i18n.t('editImage')}`}
                          >
                            <Upload
                              multiple
                              disabled={isLoading}
                              accept=".jpg, .png"
                              maxCount={1}
                              fileList={fileList}
                              onChange={handleFileListChange}
                              onRemove={() => {
                                setFileList([]);
                                setInputValue('');
                                setSendType(MESSAGE_TYPE.文字);
                              }}
                            >
                              <Image style={{ cursor: 'pointer' }} src={uploadPhotoMessageIcon} preview={false} />
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Button type="primary" onClick={onSendMember} loading={isLoading}>{i18n.t('send')}</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
          </TabPane>
          { isOurService &&
          <TabPane key={TAB.客服中心} tab={i18n.t('serviceCenter')} disabled={openChat}>
            <Card
              bodyStyle={{
                height: 550,
                padding: '10px 0px',
                background: '#F9F9F9'
              }}
              title={
                <Row align="middle" justify="space-between">
                  <Col style={{ cursor: 'pointer' }} onClick={() => onCloseChat(TAB.客服中心)}>
                    <span ><LeftOutlined />{i18n.t('exitChat')}</span>
                    <span style={{
                      display: 'inline-block',
                      marginBottom: '1px',
                      marginLeft: 5
                    }}>
                        {
                          serviceName
                            ? serviceName
                            : (ChatSettingInfo
                              ? ChatSettingInfo?.Data?.DefaultNickname
                              : i18n.t('service'))
                        }</span>
                  </Col>
                </Row>
              }
            >
              <div ref={chatWindowRef}
                   style={{
                     height: 540,
                     minHeight: 540,
                     maxHeight: 540,
                     overflowY: 'auto',
                     position: 'relative'
                   }} onScroll={() => handleScroll(false)}>
                {
                  [0].map(item => (
                    <SystemMessage
                      {...(item as any)}
                      position="center"
                      text={ChatSettingInfo?.Data?.WelcomeMessage}
                      type="info"
                    />
                  ))
                }
                {
                  !isContact && !isShowCloseChatModal &&
                  <>
                    <div
                      className="center"
                      style={{
                        margin: '1rem'
                      }}>
                      <Image width={100} src={waitingServiceContactIcon} preview={false} />
                    </div>
                    <div className="center color-03">
                      {i18n.t('waitingForCustomerServicePleaseWait')}
                    </div>
                  </>
                }
                <MessageList
                  className='message-list'
                  referance={messageListReferance}
                  dataSource={serviceMessage}
                  lockable={true}
                  downButton={true}
                  downButtonBadge={10}
                  sendMessagePreview={true}
                />
                <div style={{
                  display: `${showBackToBottom ? '' : 'none'}`,
                  textAlign: 'center',
                  position: 'sticky',
                  bottom: 5,
                }}>
                  <div style={{
                    display: 'inline-block',
                    padding: '2px 5px',
                    background: '#e3e3e3',
                    border: '.5px solid #e3e3e3',
                    borderRadius: 8,
                    cursor: 'pointer'
                  }} onClick={() => scrollToBottom(0)}>
                    {i18n.t('newMessage')}
                  </div>
                </div>
              </div>
            </Card>
            <Row>
              <Col span={24}>
                <TextArea
                  maxLength={100}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  placeholder={
                    showDisplayContent ?
                      showDisplayContent : (isContact ? `${i18n.t('inputData')}` : `${i18n.t('waitingForCustomerServiceAnswer')}`)
                  }
                  bordered={false}
                  disabled={!isContact || isLoading}
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onKeyDown={e => handleKeyDown(e)}
                />
              </Col>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col span={6} className="ml-1">
                    <Form.Item name="fileList" valuePropName="fileList">
                      <ImgCrop
                        aspect={1}
                        showGrid
                        showReset
                        resetText={`${i18n.t('reset')}`}
                        modalTitle={`${i18n.t('editImage')}`}
                      >
                        <Upload
                          multiple
                          disabled={isLoading || !isContact}
                          accept=".jpg, .png"
                          maxCount={1}
                          fileList={fileList}
                          onChange={handleFileListChange}
                          onRemove={() => {
                            setFileList([]);
                            setInputValue('');
                            setSendType(MESSAGE_TYPE.文字);
                          }}
                        >
                          <Image style={{ cursor: 'pointer' }} src={uploadPhotoMessageIcon} preview={false} />
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Button type="primary" onClick={onSend} disabled={!isContact} loading={isLoading}>
                      {i18n.t('send')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane> }
        </Tabs>
      </Form>
      {/* Modal */}
      <InquiryWindow isOpen={isOpenModal} close={() => setIsOpenModal(false)} action={handleSubmit} msg={i18n.t('endTheCurrentConversation')}/>
      <PopupCloseChatNotify isOpen={isShowCloseChatModal} close={() => setIsShowCloseChatModal(false)}
        onLeaveChatRoom={onLeaveChatRoom} />
    </>
  );
};

export default PageMain;
