import { message } from 'antd';
import { RESPONSE_CODE } from 'constants/response';
import i18n from 'i18n';
import QueryString from 'qs';
import useSWR from 'swr';
import Cookies from "js-cookie";
import { COOKIE } from "constants/cookie";

const requestInformation = (data: any) => {
  const query = QueryString.stringify(data, { addQueryPrefix: true });
  const token = Cookies.get(COOKIE.TOKEN);
  const headers: any = {
    'Content-Type': 'application/json'
  }
  if (token) headers.Authorization = `${token}`;
  return {
    query,
    headers,
    token
  }
}

// 回應狀態碼判斷
const responseProcessing = async (response: any) => {
  const body = await response.text();
  const bodyObject = JSON.parse(body)
  const res: any = body ? bodyObject : {};
  return res;
}

// 錯誤訊息及處理
const errorAlert = (errorCode: string, resCode: any) => {
  // 未驗證代理流程
  if (errorCode === 'NOT_VERIFLIED') {
    window.location.replace('/#/verify');
    return;
  } 
  // 沒有權限
  else if (errorCode === 'PERMISSION_DENIED') {
    window.location.replace('/#/');
  }
  else if (errorCode === 'UNAUTHORIZATION') {
    window.location.replace('/#/login/UNAUTHORIZATION');
    Cookies.remove(COOKIE.TOKEN);
    return;
  }
  // 代理凍結
  else if (errorCode === 'ACCOUNT_BLOCKED' || errorCode === 'AGENT_SUSPENDED') {
    if (window.location.hash.includes('#/login')) {
      message.error(RESPONSE_CODE['ACCOUNT_BLOCKED']);
      return;
    } else {
      window.location.replace('/#/login/ACCOUNT_BLOCKED');
      Cookies.remove(COOKIE.TOKEN);
      return;
    }
  }

  const codeI18n: any = {
    ...RESPONSE_CODE,
    ...resCode
  }

  message.error(codeI18n[errorCode] || i18n.t('incorrectRequest'));
}

// (新版)用fetch
export const $get = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
}) => {

  const { query, headers, token } = requestInformation(params);

  return useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return fetch(`/${url}${query}`, {
      method: 'GET',
      headers
    })
      .then(async response => {
        const res = await responseProcessing(response);
        // 成功
        if (res.State === "Success") {
          success(res);
          const result: any = {
            Data: res,
          }
          return result.Data;

          // 失敗(通用)
        } else {
          if (showMessage) errorAlert(res.Message, resCode);
          fail(res);

          const result: any = {
            Data: [],
          }
          return result.Data;
        }
      })

  }, { revalidateOnFocus: false });
}

// (新版)用fetch
export const $post = async ({
  url,
  send = {},
  showMessage = true,
  resCode = {},
  queryHeader = false,
  success = (data: any) => {},
  fail = (data: any) => {},
}: any,
setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  const res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (res.State === "Success") {
    success(res);
  } else {
    if (showMessage) errorAlert(res.Message, resCode);
    fail(res);
  }

  setLoading(false);
}